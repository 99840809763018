import { Add, ArrowDropDown, Delete, Edit } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, ListItemIcon, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { useEffect, useState } from "react";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";
import { IRegiao } from "../../../../../core/interfaces/IRegiao";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { ConfirmarAcaoTextoModal } from "../../../../../core/components/ConfirmarAcaoTextoModal";
import { FormularioRegioesEntrega } from "../../../../../configuracoes/containers/FormularioRegioesEntrega";

interface RegioesEntregaMarketplacePaperProps {
    marketplace: IMarketplace
    refresh: () => void;
    isLoading: boolean;
}

export const RegioesEntregaMarketplacePaper = ({ marketplace, refresh, isLoading }: RegioesEntregaMarketplacePaperProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openAcoes = Boolean(anchorEl);

    const abrirAcoes = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const fecharAcoes = () => {
        setAnchorEl(null);
    };

    const { salvar, loading } = useMarketplaces();

    const [regioesSelecionadas, setRegioesSelecionadas] = useState<IRegiao[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState<number>(0);

    const { real } = useMoeda();
    const { dataHoraBr } = useDataHora();

    const [formularioAberto, setFormularioAberto] = useState(false);
    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => {
        setFormularioAberto(false);
        setRegiaoEdicao(undefined);
    }

    const [confirmacaoAberta, setConfirmacaoAberta] = useState(false);
    const abrirConfirmacao = () => setConfirmacaoAberta(true);
    const fecharConfirmacao = () => setConfirmacaoAberta(false);

    const [regiaoEdicao, setRegiaoEdicao] = useState<IRegiao | undefined>(undefined);
    const abrirEdicao = (regiao?: IRegiao) => {
        setRegiaoEdicao(regiao);
        abrirFormulario();
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selecionarRegiao = (regiao: IRegiao) => {
        const selectedIndex = regioesSelecionadas.findIndex(s => s.uuid === regiao.uuid);
        let newSelected: IRegiao[] = [];

        if (selectedIndex === -1) {
            newSelected = [...regioesSelecionadas, regiao];
        } else {
            newSelected = [...regioesSelecionadas.slice(0, selectedIndex), ...regioesSelecionadas.slice(selectedIndex + 1)];
        }

        setRegioesSelecionadas(newSelected);
    };

    const limparRegioesSelecionadas = () => setRegioesSelecionadas([]);

    const regioesFiltradas = marketplace.configuracao?.regioesEntrega?.filter((regiao: IRegiao) =>
        regiao.descricao.toLowerCase().includes(search.toLowerCase())
    );

    const regioesPaginadas = regioesFiltradas?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    ) ?? [];

    useEffect(() => {
        setPage(0);
    }, [search]);

    useEffect(() => {
        limparRegioesSelecionadas();
    }, [])

    const cadastrarRegiao = async (regiao: IRegiao) => {
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                regioesEntrega: [...marketplace.configuracao?.regioesEntrega ?? [], regiao]
            }
        })

        refresh();
        return regiao;
    }

    const editarRegiao = async (regiao: IRegiao) => {
        const regiaoIndex = marketplace?.configuracao?.regioesEntrega?.findIndex(r => r.uuid === regiao.uuid);

        if (regiaoIndex === -1 || regiaoIndex === undefined) {
            return false;
        }

        const regioesAtualizadas = [...(marketplace.configuracao?.regioesEntrega ?? [])];
        regioesAtualizadas[regiaoIndex] = regiao;

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                regioesEntrega: regioesAtualizadas,
            },
        });

        limparRegioesSelecionadas();
        refresh();
        fecharAcoes();
        return true;
    };


    const excluirRegioes = async () => {
        const regioesRestantes = marketplace.configuracao?.regioesEntrega?.filter(
            regiao => !regioesSelecionadas.some(r => r.uuid === regiao.uuid)
        ) ?? [];

        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                regioesEntrega: regioesRestantes
            }
        });

        limparRegioesSelecionadas();
        fecharAcoes();
        refresh();
        fecharConfirmacao();
    };


    return (
        <>
            <FormularioRegioesEntrega
                formularioAberto={formularioAberto}
                fecharFormulario={fecharFormulario}
                cadastrarRegiao={cadastrarRegiao}
                editarRegiao={editarRegiao}
                valoresIniciais={regiaoEdicao}
                habilitarEdicao={regiaoEdicao ? true : false}
            />
            <ConfirmarAcaoTextoModal
                titulo={`Excluir ${regioesSelecionadas.length == 1 ? "região" : "regiões"}`}
                mensagem={<Typography>Para confirmar a exclusão, digite <b>Confirmar</b> no campo.</Typography>}
                cancelar={fecharConfirmacao}
                confirmar={excluirRegioes}
                open={confirmacaoAberta}
                parametro="Confirmar"
            />
            <Paper sx={{ width: "100%", p: 2, minHeight: "180px", height: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography variant="h6">Regiões de entrega</Typography>
                    <Box>
                        <TextField
                            value={search}
                            placeholder="Descrição"
                            size="small"
                            label="Pesquisa"
                            sx={{ mr: 2, width: "200px" }}
                            onChange={handleSearchChange}
                            className="filtro-regiao-entrega"
                        />
                        <Button
                            disabled={regioesSelecionadas.length <= 0}
                            variant="contained"
                            endIcon={
                                <ArrowDropDown
                                    sx={{ transform: openAcoes ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                                />
                            }
                            onClick={abrirAcoes}
                        >
                            Ações
                        </Button>
                        <Menu
                            id={`acoes-regioes-menu`}
                            MenuListProps={{
                                'aria-labelledby': `acoes-regioes-menu`,
                            }}
                            anchorEl={anchorEl}
                            open={openAcoes}
                            onClose={fecharAcoes}
                        >
                            <MenuItem disabled>
                                <Typography variant="inherit" noWrap>
                                    {regioesSelecionadas?.length} {regioesSelecionadas?.length == 1 ? "região" : "regiões"} {regioesSelecionadas?.length == 1 ? "selecionada" : "selecionadas"}
                                </Typography>
                            </MenuItem>
                            <Divider />
                            <Tooltip title='Edite a região'>
                                <MenuItem onClick={() => abrirEdicao(regioesSelecionadas[0])} disabled={regioesSelecionadas?.length != 1}>
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        Editar
                                    </Typography>
                                </MenuItem>
                            </Tooltip>
                            <Tooltip title='Exclua a região'>
                                <MenuItem onClick={abrirConfirmacao} disabled={regioesSelecionadas?.length < 1}>
                                    <ListItemIcon>
                                        <Delete fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" noWrap>
                                        Excluir
                                    </Typography>
                                </MenuItem>
                            </Tooltip>
                        </Menu>
                        <Button
                            disabled={regioesSelecionadas.length > 0}
                            onClick={abrirFormulario}
                            sx={{ ml: "1rem" }}
                            variant="contained"
                            endIcon={<Add />}
                        >
                            Cadastrar
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ padding: "1rem", marginTop: "1rem" }}>
                    <TableContainer component={Box}>
                        <Table sx={{ minWidth: 650 }} size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        Descrição
                                    </TableCell>
                                    <TableCell>
                                        Preço
                                    </TableCell>
                                    <TableCell>
                                        Cadastrada às
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : regioesPaginadas.length > 0 ? (
                                    regioesPaginadas.map((regiao) => {
                                        const isItemSelected = regioesSelecionadas.some(s => s.uuid === regiao.uuid);

                                        return (
                                            <TableRow
                                                key={regiao.uuid}
                                                selected={isItemSelected}
                                                onClick={() => selecionarRegiao(regiao)}
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {regiao.descricao}
                                                </TableCell>
                                                <TableCell>{real(regiao.preco)}</TableCell>
                                                <TableCell>{dataHoraBr(regiao.cadastradaAs as unknown as string)}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                            Nenhuma região configurada
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={regioesFiltradas?.length ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Linhas por página"
                        />
                    </TableContainer>
                </Box>
            </Paper>

        </>
    );
}