
import { useState } from "react";
import { TipoCobrancaHeader } from "./styles";
import { useConfiguracoesPedidoContext } from "../../../pedidos/contexts/ConfiguracoesPedidoContext";
import { RadioCard } from "../../../core/components/RadioCard";
import { TipoCobrancaEntrega, TipoCobrancaEntregaDescricao, TipoCobrancaEntregaTitulo } from "../../../pedidos/enum/TipoCobrancaEntrega";
import { RegioesEntregaContainer } from "./containers/RegioesEntregaContainer";
import { DistanciasEntregaContainer } from "./containers/DistanciasEntregaContainer";
import { Box, RadioGroup } from "@mui/material";


export const Entrega = () => {
    const { configuracao, alterarTipoCobrancaEntrega } = useConfiguracoesPedidoContext();
    const [tipoCobranca, setTipoCobranca] = useState<TipoCobrancaEntrega>(configuracao?.tipoCobrancaEntrega ?? TipoCobrancaEntrega.DISTANCIA);

    const handleTipoCobranca = (tipo: TipoCobrancaEntrega) => {
        setTipoCobranca(tipo);
        alterarTipoCobrancaEntrega(tipo);
    }


    return (
        <Box sx={{ width: "100%", minHeight: "180px", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <TipoCobrancaHeader>
                <RadioGroup
                    name="tipo-cobranca-entrega"
                    row
                    sx={{ display: "flex", justifyContent: "center", gap: '2rem' }}
                    onChange={(e) => handleTipoCobranca(e.target?.value as TipoCobrancaEntrega)}
                >
                    <RadioCard
                        labelTitle={TipoCobrancaEntregaTitulo.DISTANCIA}
                        labelDescription={TipoCobrancaEntregaDescricao.DISTANCIA}
                        labelValue={TipoCobrancaEntrega.DISTANCIA}
                        key={TipoCobrancaEntrega.DISTANCIA}
                        selected={tipoCobranca == TipoCobrancaEntrega.DISTANCIA}
                        onClick={() => handleTipoCobranca(TipoCobrancaEntrega.DISTANCIA)}
                    />
                    <RadioCard
                        labelTitle={TipoCobrancaEntregaTitulo.REGIAO}
                        labelDescription={TipoCobrancaEntregaDescricao.REGIAO}
                        labelValue={TipoCobrancaEntrega.REGIAO}
                        key={TipoCobrancaEntrega.REGIAO}
                        selected={tipoCobranca == TipoCobrancaEntrega.REGIAO}
                        onClick={() => handleTipoCobranca(TipoCobrancaEntrega.REGIAO)}
                    />
                </RadioGroup>
            </TipoCobrancaHeader>
            {tipoCobranca == TipoCobrancaEntrega.REGIAO && <RegioesEntregaContainer />}
            {tipoCobranca == TipoCobrancaEntrega.DISTANCIA && <DistanciasEntregaContainer />}
        </Box>
    )
}