import { Box, Button, Checkbox, CircularProgress, Divider, ListItemIcon, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { EntregaTools } from "../../styles";
import { Add, ArrowDropDown, Delete, Edit } from "@mui/icons-material";
import { ConfirmarAcaoTextoModal } from "../../../../../core/components/ConfirmarAcaoTextoModal";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { useState } from "react";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useConfiguracoesPedidoContext } from "../../../../../pedidos/contexts/ConfiguracoesPedidoContext";
import { IDistancia } from "../../../../../pedidos/interface/IDistancia";
import { FormularioDistanciasEntrega } from "../../../../containers/FormularioDistanciasEntrega";

export const DistanciasEntregaContainer = () => {
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [distanciasPorPagina, setDistanciasPorPagina] = useState(5);
    const [distanciasSelecionadas, setDistanciasSelecionadas] = useState<IDistancia[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openAcoes = Boolean(anchorEl);

    const abrirAcoes = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const fecharAcoes = () => {
        setAnchorEl(null);
    };

    const selecionarDistancia = (distancia: IDistancia) => {
        const selectedIndex = distanciasSelecionadas.findIndex(s => s.uuid === distancia.uuid);
        let newSelected: IDistancia[] = [];

        if (selectedIndex === -1) {
            newSelected = [...distanciasSelecionadas, distancia];
        } else {
            newSelected = [...distanciasSelecionadas.slice(0, selectedIndex), ...distanciasSelecionadas.slice(selectedIndex + 1)];
        }

        setDistanciasSelecionadas(newSelected);
    };

    const limparDistanciasSelecionadas = () => setDistanciasSelecionadas([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, novaPagina: number) => {
        setPaginaAtual(novaPagina);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDistanciasPorPagina(parseInt(event.target.value, 10));
        setPaginaAtual(0);
    };

    const { configuracao, loading, cadastrarDistancia, editarDistancia, excluirDistancia } = useConfiguracoesPedidoContext();

    const [formularioAberto, setFormularioAberto] = useState(false);
    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => {
        setFormularioAberto(false);
        setDistanciaEdicao(undefined);
    }

    const [confirmacaoAberta, setConfirmacaoAberta] = useState(false);
    const abrirConfirmacao = () => setConfirmacaoAberta(true);
    const fecharConfirmacao = () => setConfirmacaoAberta(false);

    const [distanciaEdicao, setDistanciaEdicao] = useState<IDistancia | undefined>(undefined);
    const abrirEdicao = (distancia?: IDistancia) => {
        setDistanciaEdicao(distancia);
        abrirFormulario();
    }

    const handleExcluirDistancias = async () => {
        for (const distanciaSelecionada of distanciasSelecionadas) {
            if (!distanciaSelecionada.uuid) return;
            await excluirDistancia(distanciaSelecionada.uuid);
        }
        fecharAcoes();
        limparDistanciasSelecionadas();
        fecharConfirmacao();
    };

    const { real } = useMoeda();


    const distanciasExibidas: IDistancia[] = configuracao?.distanciasEntrega?.slice(paginaAtual * distanciasPorPagina, (paginaAtual + 1) * distanciasPorPagina) ?? [];
    const { dataHoraBr } = useDataHora();

    const handleEditarDistancia = async (distancia: IDistancia) => {
        const result = await editarDistancia(distancia);
        limparDistanciasSelecionadas();
        fecharAcoes();
        return result;
    }

    return (
        <>
            <EntregaTools variant="outlined">
                <Typography variant="h6">Distâncias de entrega</Typography>
                <Box>
                    <Button
                        disabled={distanciasSelecionadas.length <= 0}
                        variant="contained"
                        endIcon={
                            <ArrowDropDown
                                sx={{ transform: openAcoes ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                            />
                        }
                        onClick={abrirAcoes}
                    >
                        Ações
                    </Button>
                    <Menu
                        id={`acoes-distancias-menu`}
                        MenuListProps={{
                            'aria-labelledby': `acoes-distancias-menu`,
                        }}
                        anchorEl={anchorEl}
                        open={openAcoes}
                        onClose={fecharAcoes}
                    >
                        <MenuItem disabled>
                            <Typography variant="inherit" noWrap>
                                {distanciasSelecionadas?.length} {distanciasSelecionadas?.length == 1 ? "distância" : "distâncias"} {distanciasSelecionadas?.length == 1 ? "selecionada" : "selecionadas"}
                            </Typography>
                        </MenuItem>
                        <Divider />
                        <Tooltip title='Edite a distância'>
                            <MenuItem onClick={() => abrirEdicao(distanciasSelecionadas[0])} disabled={distanciasSelecionadas?.length != 1}>
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Editar
                                </Typography>
                            </MenuItem>
                        </Tooltip>
                        <Tooltip title='Exclua a distância'>
                            <MenuItem onClick={abrirConfirmacao} disabled={distanciasSelecionadas?.length < 1}>
                                <ListItemIcon>
                                    <Delete fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Excluir
                                </Typography>
                            </MenuItem>
                        </Tooltip>
                    </Menu>
                    <Button
                        disabled={distanciasSelecionadas.length > 0}
                        onClick={abrirFormulario}
                        sx={{ ml: "1rem" }}
                        variant="contained"
                        endIcon={<Add />}
                    >
                        Cadastrar
                    </Button>
                </Box>
            </EntregaTools>
            <Box sx={{ padding: "1rem", marginTop: "1rem" }}>
                <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    De
                                </TableCell>
                                <TableCell>
                                    Até
                                </TableCell>
                                <TableCell>
                                    Preço
                                </TableCell>
                                <TableCell>
                                    Data/hora
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : distanciasExibidas.length > 0 ? (
                                distanciasExibidas.map((distancia: IDistancia) => {
                                    const isItemSelected = distanciasSelecionadas.some(s => s.uuid === distancia.uuid);

                                    return (
                                        <TableRow
                                            key={distancia.uuid}
                                            selected={isItemSelected}
                                            onClick={() => selecionarDistancia(distancia)}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {distancia.inicio}km
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {distancia.fim}km
                                            </TableCell>
                                            <TableCell>{real(distancia.valor)}</TableCell>
                                            <TableCell>{dataHoraBr(distancia.dataHora)}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        Nenhuma distância configurada
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={distanciasExibidas?.length ?? 0}
                        rowsPerPage={distanciasPorPagina}
                        page={paginaAtual}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página"
                    />
                </TableContainer>
            </Box>
            <ConfirmarAcaoTextoModal
                titulo={`Excluir ${distanciasSelecionadas.length == 1 ? "distância" : "distâncias"}`}
                mensagem={<Typography>Para confirmar a exclusão, digite <b>Confirmar</b> no campo.</Typography>}
                cancelar={fecharConfirmacao}
                confirmar={handleExcluirDistancias}
                open={confirmacaoAberta}
                parametro="Confirmar"
            />
            <FormularioDistanciasEntrega
                formularioAberto={formularioAberto}
                fecharFormulario={fecharFormulario}
                cadastrarDistancia={cadastrarDistancia}
                editarDistancia={handleEditarDistancia}
                valoresIniciais={distanciaEdicao}
                habilitarEdicao={distanciaEdicao ? true : false}
            />
        </>
    );
}