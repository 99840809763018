import { useState } from "react";
import { ApiFactory } from "../../core/config/ApiFactory"
import { IEndereco } from "../../core/interfaces/IEndereco";
import { IDistancia } from "../interface/IDistancia";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { AxiosError } from "axios";

export const useEntregas = () => {
    const apiPedidos = ApiFactory.getApi("MS_PEDIDOS");
    const [loading, setLoading] = useState(false);
    const notificar = useSnackbar();

    const calcularFrete = async (props: {
        enderecoOrigem: IEndereco,
        enderecoDestino: IEndereco,
        distancias: IDistancia[]
    }) => {
        setLoading(true);

        try {
            const { data } = await apiPedidos.post('/entregas/pdv/calcular-frete', props);
            return data;
        } catch (error) {
            if(error instanceof AxiosError) {
                const mensagemErro = error.response?.data?.message;

                if(mensagemErro) {
                    return notificar(mensagemErro, { severity: 'error' });
                }
            }

            notificar("Ocorreu um erro ao calcular o frete", { severity: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return { calcularFrete, loading }
}