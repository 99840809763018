import { Box, Button, Checkbox, CircularProgress, Divider, ListItemIcon, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { EntregaTools } from "../../styles";
import { Add, ArrowDropDown, Delete, Edit } from "@mui/icons-material";
import { ConfirmarAcaoTextoModal } from "../../../../../core/components/ConfirmarAcaoTextoModal";
import { FormularioRegioesEntrega } from "../../../../containers/FormularioRegioesEntrega";
import { IRegiao } from "../../../../../core/interfaces/IRegiao";
import { useDataHora } from "../../../../../core/hooks/useDataHora";
import { useState } from "react";
import { useMoeda } from "../../../../../core/hooks/useMoeda";
import { useConfiguracoesPedidoContext } from "../../../../../pedidos/contexts/ConfiguracoesPedidoContext";

export const RegioesEntregaContainer = () => {
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [regioesPorPagina, setRegioesPorPagina] = useState(5);
    const [regioesSelecionadas, setRegioesSelecionadas] = useState<IRegiao[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openAcoes = Boolean(anchorEl);

    const abrirAcoes = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const fecharAcoes = () => {
        setAnchorEl(null);
    };

    const selecionarRegiao = (regiao: IRegiao) => {
        const selectedIndex = regioesSelecionadas.findIndex(s => s.uuid === regiao.uuid);
        let newSelected: IRegiao[] = [];

        if (selectedIndex === -1) {
            newSelected = [...regioesSelecionadas, regiao];
        } else {
            newSelected = [...regioesSelecionadas.slice(0, selectedIndex), ...regioesSelecionadas.slice(selectedIndex + 1)];
        }

        setRegioesSelecionadas(newSelected);
    };

    const limparRegioesSelecionadas = () => setRegioesSelecionadas([]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, novaPagina: number) => {
        setPaginaAtual(novaPagina);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegioesPorPagina(parseInt(event.target.value, 10));
        setPaginaAtual(0);
    };

    const { configuracao, loading, cadastrarRegiao, excluirRegiao, editarRegiao, alterarTipoCobrancaEntrega } = useConfiguracoesPedidoContext();

    const [formularioAberto, setFormularioAberto] = useState(false);
    const abrirFormulario = () => setFormularioAberto(true);
    const fecharFormulario = () => {
        setFormularioAberto(false);
        setRegiaoEdicao(undefined);
    }

    const [confirmacaoAberta, setConfirmacaoAberta] = useState(false);
    const abrirConfirmacao = () => setConfirmacaoAberta(true);
    const fecharConfirmacao = () => setConfirmacaoAberta(false);

    const [regiaoEdicao, setRegiaoEdicao] = useState<IRegiao | undefined>(undefined);
    const abrirEdicao = (regiao?: IRegiao) => {
        setRegiaoEdicao(regiao);
        abrirFormulario();
    }

    const handleExcluirRegioes = async () => {
        for (const regiaoSelecionada of regioesSelecionadas) {
            if (!regiaoSelecionada.uuid) return;
            await excluirRegiao(regiaoSelecionada.uuid);
        }
        fecharAcoes();
        limparRegioesSelecionadas();
        fecharConfirmacao();
    };

    const { real } = useMoeda();

    const [filtro, setFiltro] = useState('');

    const handleFiltroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFiltro(event.target.value);
    };

    const regioesFiltradas = configuracao?.regioesEntrega.filter((regiao: IRegiao) =>
        regiao.descricao.toLowerCase().includes(filtro.toLowerCase())
    );

    const regioesExibidas: IRegiao[] = regioesFiltradas?.slice(paginaAtual * regioesPorPagina, (paginaAtual + 1) * regioesPorPagina) ?? [];
    const { dataHoraBr } = useDataHora();

    const handleEditarRegiao = async (regiao: IRegiao) => {
        const result = await editarRegiao(regiao);
        limparRegioesSelecionadas();
        fecharAcoes();
        return result;
    }

    return (
        <>
            <EntregaTools variant="outlined">
                <Typography variant="h6">Regiões de entrega</Typography>
                <Box>
                    <TextField
                        value={filtro}
                        placeholder="Descrição"
                        size="small"
                        label="Pesquisa"
                        sx={{ mr: 2, width: "200px" }}
                        onChange={handleFiltroChange}
                        className="filtro-regiao-entrega"
                    />
                    <Button
                        disabled={regioesSelecionadas.length <= 0}
                        variant="contained"
                        endIcon={
                            <ArrowDropDown
                                sx={{ transform: openAcoes ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                            />
                        }
                        onClick={abrirAcoes}
                    >
                        Ações
                    </Button>
                    <Menu
                        id={`acoes-regioes-menu`}
                        MenuListProps={{
                            'aria-labelledby': `acoes-regioes-menu`,
                        }}
                        anchorEl={anchorEl}
                        open={openAcoes}
                        onClose={fecharAcoes}
                    >
                        <MenuItem disabled>
                            <Typography variant="inherit" noWrap>
                                {regioesSelecionadas?.length} {regioesSelecionadas?.length == 1 ? "região" : "regiões"} {regioesSelecionadas?.length == 1 ? "selecionada" : "selecionadas"}
                            </Typography>
                        </MenuItem>
                        <Divider />
                        <Tooltip title='Edite a região'>
                            <MenuItem onClick={() => abrirEdicao(regioesSelecionadas[0])} disabled={regioesSelecionadas?.length != 1}>
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Editar
                                </Typography>
                            </MenuItem>
                        </Tooltip>
                        <Tooltip title='Exclua a região'>
                            <MenuItem onClick={abrirConfirmacao} disabled={regioesSelecionadas?.length < 1}>
                                <ListItemIcon>
                                    <Delete fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Excluir
                                </Typography>
                            </MenuItem>
                        </Tooltip>
                    </Menu>
                    <Button
                        disabled={regioesSelecionadas.length > 0}
                        onClick={abrirFormulario}
                        sx={{ ml: "1rem" }}
                        variant="contained"
                        endIcon={<Add />}
                    >
                        Cadastrar
                    </Button>
                </Box>
            </EntregaTools>
            <Box sx={{ padding: "1rem", marginTop: "1rem", maxWidth: 500 }}>
                <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    Descrição
                                </TableCell>
                                <TableCell>
                                    Preço
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : regioesExibidas.length > 0 ? (
                                regioesExibidas.map((regiao) => {
                                    const isItemSelected = regioesSelecionadas.some(s => s.uuid === regiao.uuid);

                                    return (
                                        <TableRow
                                            key={regiao.uuid}
                                            selected={isItemSelected}
                                            onClick={() => selecionarRegiao(regiao)}
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {regiao.descricao}
                                            </TableCell>
                                            <TableCell>{real(regiao.preco)}</TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                        Nenhuma região configurada
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        component="div"
                        count={regioesFiltradas?.length ?? 0}
                        rowsPerPage={regioesPorPagina}
                        page={paginaAtual}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Linhas por página"
                    />
                </TableContainer>
            </Box>
            <ConfirmarAcaoTextoModal
                titulo={`Excluir ${regioesSelecionadas.length == 1 ? "região" : "regiões"}`}
                mensagem={<Typography>Para confirmar a exclusão, digite <b>Confirmar</b> no campo.</Typography>}
                cancelar={fecharConfirmacao}
                confirmar={handleExcluirRegioes}
                open={confirmacaoAberta}
                parametro="Confirmar"
            />
            <FormularioRegioesEntrega
                formularioAberto={formularioAberto}
                fecharFormulario={fecharFormulario}
                cadastrarRegiao={cadastrarRegiao}
                editarRegiao={handleEditarRegiao}
                valoresIniciais={regiaoEdicao}
                habilitarEdicao={regiaoEdicao ? true : false}
            />
        </>
    );
}