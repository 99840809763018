import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { regiaoEntregaSchema } from "../../../pedidos/schemas/regiaoEntregaSchema";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { IDistancia } from "../../../pedidos/interface/IDistancia";
import { distanciaEntregaSchema } from "../../../pedidos/schemas/distanciaEntregaSchema";

interface FormularioDistanciasEntregaProps {
    fecharFormulario: () => void;
    formularioAberto: boolean;
    cadastrarDistancia: (distancia: IDistancia) => Promise<IDistancia | null>
    editarDistancia?: (distancia: IDistancia) => Promise<boolean>
    valoresIniciais?: IDistancia;
    habilitarEdicao?: boolean
}

export const FormularioDistanciasEntrega = ({ formularioAberto, fecharFormulario, cadastrarDistancia, valoresIniciais, editarDistancia, habilitarEdicao }: FormularioDistanciasEntregaProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { number } = useMoeda();

    const { reset, register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        defaultValues: {
            valor: valoresIniciais?.valor ?? '',
            inicio: valoresIniciais?.inicio ?? '',
            fim: valoresIniciais?.fim ?? '',
        },
        resolver: yupResolver(distanciaEntregaSchema),
        mode: "onChange",
    });

    const handleCadastrarDistancia = async (distancia: FieldValues) => {
        setLoading(true);

        if(!habilitarEdicao && cadastrarDistancia) {
            const result = await cadastrarDistancia({
                valor: number(distancia.valor),
                fim: Number(distancia.fim),
                inicio: Number(distancia.inicio)
            });
        
            setLoading(false);
            if (result) {
                handleFecharFormulario();
            }
        }

        if(habilitarEdicao && valoresIniciais && editarDistancia) {
            const result = await editarDistancia({
                valor: number(distancia.valor),
                fim: Number(distancia.fim),
                inicio: Number(distancia.inicio),
                uuid: valoresIniciais.uuid
            });
        
            setLoading(false);
            if (result == true) {
                handleFecharFormulario();
            }
        }
    }

    const handleFecharFormulario = () => {
        reset();
        fecharFormulario();
    }

    useEffect(() => {
        if (valoresIniciais && formularioAberto) {
            setValue('fim', valoresIniciais.fim);
            setValue('valor', valoresIniciais.valor);
            setValue('inicio', valoresIniciais.inicio);
        }
    }, [valoresIniciais, formularioAberto, setValue]);

    useEffect(() => {
        if (formularioAberto) {
            reset({
                inicio: valoresIniciais?.inicio ?? '',
                fim: valoresIniciais?.fim ?? '',
                valor: valoresIniciais?.valor ?? ''
            });
        }
    }, [formularioAberto, valoresIniciais, reset]);
    

    useEffect(() => {
        if (formularioAberto) {
            reset();
        }
    }, [formularioAberto, reset]);

    return (
        <Dialog fullWidth open={formularioAberto} onClose={handleFecharFormulario}>
            <DialogTitle>{habilitarEdicao ? "Editar" : "Cadastrar"} distância</DialogTitle>
            <form onSubmit={handleSubmit(handleCadastrarDistancia)}>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Início (km)"
                            type="text"
                            fullWidth
                            value={watch('inicio')}
                            {...register("inicio")}
                            error={!!errors.inicio}
                            helperText={<>{errors.inicio?.message}</>}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Fim (km)"
                            type="text"
                            fullWidth
                            value={watch('fim')}
                            {...register("fim")}
                            error={!!errors.fim}
                            helperText={<>{errors.fim?.message}</>}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 3 }}>
                        <InputLabel error={!!errors.valor} htmlFor="valor-distancia">Valor</InputLabel>
                        <OutlinedInput
                            id="valor-distancia"
                            error={!!errors.valor}
                            startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                            label="Valor"
                            fullWidth
                            value={watch('valor')}
                            {...register("valor")}
                        />
                        {!!errors.valor && <FormHelperText error={true}><>{errors.valor?.message}</></FormHelperText>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fecharFormulario} variant="outlined">Cancelar</Button>
                    <Button type="submit" variant="contained">
                        {loading == false ? (
                            <>{habilitarEdicao ? "Editar" : "Cadastrar"}</>
                        ) : (
                            <CircularProgress color="inherit" />
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}