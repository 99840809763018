import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, TextField } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import { metodoPagamentoSchema } from "../../../pedidos/schemas/metodoPagamentoSchema";

interface FormularioNovoMetodoPagamentoProps {
    fecharFormulario: () => void;
    formularioAberto: boolean;
    adicionarMetodoPagamento: (metodo: string) => void;
    metodosExistentes: string[];
}

export const NovoMetodoPagamento = ({ adicionarMetodoPagamento, fecharFormulario, formularioAberto, metodosExistentes }: FormularioNovoMetodoPagamentoProps) => {

    const { reset, watch, register, handleSubmit, formState: { errors }, setError, setValue } = useForm({
        resolver: yupResolver(metodoPagamentoSchema),
        mode: "onChange",
    });

    const handleAdicionarMetodoPagamento = ({ metodoPagamento }: FieldValues) => {
        if(metodosExistentes.includes(metodoPagamento.trim())) {
            setError("metodoPagamento", { 
                message: "Método de pagamento já existente"
            });
            return;
        }

        adicionarMetodoPagamento(metodoPagamento.trim());
        handleFecharFormulario();
    }

    const handleFecharFormulario = () => {
        reset();
        fecharFormulario();
        setValue("metodoPagamento", "")
    }

    return (
        <Dialog fullWidth open={formularioAberto} onClose={handleFecharFormulario}>
            <DialogTitle>Novo método customizado</DialogTitle>
            <form onSubmit={handleSubmit(handleAdicionarMetodoPagamento)}>
                <DialogContent>
                    <Alert severity="info" sx={{ marginBottom: "2rem" }}>
                        <AlertTitle>Lembrete Importante</AlertTitle>
                        Para salvar novos métodos de pagamento personalizados, 
                        assegure-se de movê-los para o lado direito da lista de transferência. 
                        <br />
                        <br />
                        Métodos mantidos à esquerda não serão salvos e suas alterações poderão ser perdidas. 
                        Verifique sua seleção antes de finalizar para garantir que todas as suas preferências sejam corretamente atualizadas.
                    </Alert>
                    <FormControl fullWidth>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Descrição"
                            type="text"
                            fullWidth
                            {...register("metodoPagamento")}
                            error={!!errors.metodoPagamento}
                            helperText={<>{errors.metodoPagamento?.message}</>}
                            value={watch("metodoPagamento")}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleFecharFormulario}>Cancelar</Button>
                    <Button type="submit" variant="contained">
                        Adicionar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>

    );
}