import { TextField, Typography, Button, Select, MenuItem, FormControl, InputLabel, FormHelperText, Autocomplete, CircularProgress } from "@mui/material";
import { ButtonsContainer, FormContainer, PaperContainer } from "./styles";
import { useMascara } from "../../../core/hooks/useMascara";
import { yupResolver } from "@hookform/resolvers/yup";
import { enderecoClienteDistanciaSchema, enderecoClienteRegiaoSchema } from "../../schemas/enderecoClienteSchema";
import { useForm } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";
import { useEffect, useRef, useState } from "react";
import { useCEP } from "../../../core/hooks/useCEP";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { ICliente } from "../../interface/ICliente";
import { useClientes } from "../../hooks/useClientes";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import { IEndereco } from "../../../core/interfaces/IEndereco";
import { IRegiao } from "../../../core/interfaces/IRegiao";
import { v4 } from "uuid";
import { useConfiguracoesPedidoContext } from "../../../pedidos/contexts/ConfiguracoesPedidoContext";
import { useFetch } from "../../../core/hooks/useFetch";
import { ApiFactory } from "../../../core/config/ApiFactory";
import { FormularioRegioesEntrega } from "../../../configuracoes/containers/FormularioRegioesEntrega";
import { UFs } from "../../../core/enum/UFs";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { TipoCobrancaEntrega } from "../../../pedidos/enum/TipoCobrancaEntrega";

interface SalvarEnderecoProps {
    cliente: ICliente
    salvarEndereco: (endereco: IEndereco, clienteUuid: string) => Promise<boolean>
    salvar: (endereco: IEndereco) => void
    loading?: boolean;
    cancelar: () => void;
    valoresIniciais?: IEndereco;
    enderecos?: IEndereco[];
}

export const SalvarEndereco = ({ cliente, salvar, cancelar, salvarEndereco, valoresIniciais, enderecos, loading }: SalvarEnderecoProps) => {
    const { cadastrarRegiao, listarRegioes, configuracao } = useConfiguracoesPedidoContext();
    const { estabelecimento } = useAutenticacaoContext();
    const [regioes, setRegioes] = useState<IRegiao[]>([]);
    const { mascaraCEP } = useMascara();
    const { setValue, register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(
            configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega?.DISTANCIA
                ? enderecoClienteDistanciaSchema
                : enderecoClienteRegiaoSchema),
        mode: "onChange",
    });

    const cepValue = watch("cep");
    const [shouldFocusCep, setShouldFocusCep] = useState(true);
    const cepRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (shouldFocusCep && cepRef.current) {
            cepRef.current.focus();
        }
    }, [shouldFocusCep]);

    const handleFieldFocus = () => {
        setShouldFocusCep(false);
    };

    const { data: dadosCep, loading: loadingCep } = useCEP(cepValue);
    const { real } = useMoeda();
    const snackbar = useSnackbar();

    const handleCadastrarRegiao = async (regiao: IRegiao) => {
        const regiaoCadastrada = await cadastrarRegiao(regiao);
        if (regiaoCadastrada) {
            setValue('regiao', regiaoCadastrada);
        }
        return regiaoCadastrada;
    }

    useEffect(() => {
        if (!enderecos || !enderecos.length) {
            setValue("observacao", `Endereço 1`);
        } else {
            setValue("observacao", `Endereço ${enderecos.length + 1}`);
        }
    }, [enderecos])

    useEffect(() => {
        if (dadosCep) {
            const { bairro, cidade, rua, uf } = dadosCep;

            if (cidade) {
                setValue("cidade", cidade);
            } else {
                const cidade = document.getElementById("cidade");
                cidade?.focus();
                return;
            }

            if (bairro) {
                setValue("bairro", bairro);
            } else {
                return;
            }

            if (rua) {
                setValue("rua", rua);
            } else {
                const rua = document.getElementById("rua");
                rua?.focus();
            }

            if (uf) {
                setValue("uf", uf);
            } else {
                setValue("uf", estabelecimento?.endereco?.uf);
            }
        }
    }, [dadosCep, regioes])

    useEffect(() => {
        setValue("cep", mascaraCEP(cepValue));
    }, [cepValue])

    useEffect(() => {
        const handleListarRegioes = async () => {
            const regioesListadas = await listarRegioes();
            if (regioesListadas) {
                setRegioes(regioesListadas);
            }
        }
        handleListarRegioes();
    }, [])

    const handleValoresIniciais = async () => {
        setValue("uf", estabelecimento?.endereco?.uf);

        if (valoresIniciais) {
            const { bairro, cidade, rua, cep, complemento, numero, regiao, uf } = valoresIniciais;

            if (bairro) {
                setValue("bairro", bairro);
            }

            if (cidade) {
                setValue("cidade", cidade);
            }

            if (rua) {
                setValue("rua", rua);
            }

            if (cep) {
                setValue("cep", cep);
            }

            if (uf) {
                setValue("uf", uf);
            }

            if (complemento) {
                setValue("complemento", complemento);
            }

            if (numero) {
                setValue("numero", numero);
            }

            if (regiao) {
                const regiaoEncontrada = regioes.find(regiaoProcurada => regiaoProcurada.uuid == regiao.uuid);
                if (regiaoEncontrada) {
                    setValue("regiao", regiaoEncontrada);
                } else {
                    const regioesListadas = await listarRegioes();
                    const regiaoEncontrada = regioesListadas.find(regiaoProcurada => regiaoProcurada.uuid == regiao.uuid);
                    if (regiaoEncontrada) {
                        setValue("regiao", regiaoEncontrada);
                    }
                    setRegioes(regioesListadas);
                }
            }
        }
    }

    useEffect(() => {
        handleValoresIniciais();
    }, [valoresIniciais])

    const handleVincularEndereco = async (endereco: FieldValues) => {
        if (!cliente.uuid) {
            return snackbar("Cliente inválido vinculado", { severity: "error" });
        }

        let novoEndereco = {
            bairro: endereco.bairro,
            cidade: endereco.cidade,
            rua: endereco.rua,
            cep: endereco.cep,
            complemento: endereco.complemento,
            numero: endereco.numero,
            uuid: valoresIniciais?.uuid ?? v4(),
            regiao: endereco.regiao,
            observacao: endereco.observacao,
            uf: endereco.uf
        };

        await salvarEndereco(novoEndereco, cliente.uuid);
        salvar(novoEndereco);
    }

    const handleRegiaoChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: IRegiao | null
    ) => {
        return setValue('regiao', value);
    };

    const handleBuscarRegioes = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValor = e.target.value.toUpperCase();
        const novaRegiao: IRegiao = {
            uuid: v4(),
            descricao: inputValor,
            preco: 0,
            cadastrar: true
        };
        const regioesFiltradas = regioes.filter(regiao => !regiao.cadastrar);
        const regioesAtualizadas = [...regioesFiltradas, novaRegiao];

        setRegioes(regioesAtualizadas);
    };


    return (
        <PaperContainer>
            <FormContainer onSubmit={handleSubmit(handleVincularEndereco)}>
                <Typography variant="h6" marginBottom="0.5rem">Endereço</Typography>
                <TextField
                    label="Observação"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("observacao")}
                    error={!!errors.observacao}
                    helperText={<>{errors.observacao?.message}</>}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="CEP"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("cep")}
                    error={!!errors.cep}
                    helperText={<>{errors.cep?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                    inputRef={cepRef}
                    onFocus={handleFieldFocus}
                />
                <FormControl style={{ width: "100%", margin: "0.7rem 0" }} error={!!errors.uf}>
                    <InputLabel id="uf-label">UF</InputLabel>
                    <Select
                        labelId="uf-label"
                        id="uf"
                        defaultValue=""
                        {...register("uf")}
                        label="UF"
                        value={watch("uf") || ""}
                    >
                        {UFs.map((uf) => (
                            <MenuItem key={uf} value={uf}>
                                {uf}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.uf && <FormHelperText><>{errors.uf?.message}</></FormHelperText>}
                </FormControl>

                <TextField
                    label="Cidade ou Localidade*"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("cidade")}
                    error={!!errors.cidade}
                    helperText={<>{errors.cidade?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                    id="cidade"
                />
                <TextField
                    label="Bairro*"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("bairro")}
                    error={!!errors.bairro}
                    helperText={<>{errors.bairro?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                    id="bairro"
                />
                <TextField
                    label="Rua ou Logradouro*"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("rua")}
                    error={!!errors.rua}
                    helperText={<>{errors.rua?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                    id="rua"
                />
                <TextField
                    label="Número"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("numero")}
                    error={!!errors.numero}
                    helperText={<>{errors.numero?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Complemento"
                    style={{ width: "100%", margin: "0.7rem 0" }}
                    {...register("complemento")}
                    error={!!errors.complemento}
                    helperText={<>{errors.complemento?.message}</>}
                    disabled={loadingCep}
                    InputLabelProps={{ shrink: true }}
                />
                {/* <FormControl error={!!errors.regiao} style={{ width: '100%', margin: "0.7rem 0" }}>
                    <Select
                        labelId="regiao-label"
                        id="regiao"
                        label="Região*"
                        {...register('regiao')}
                        value={watch('regiao') || ''}
                    >
                        {configuracoes && configuracoes.regioesEntrega?.map(regiao => {
                            return (
                                <MenuItem key={regiao.uuid} value={regiao.uuid}>
                                    {regiao.descricao} - {real(regiao.preco)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {errors.regiao && <FormHelperText>{<>{errors.regiao?.message}</>}</FormHelperText>}
                </FormControl> */}
                {configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega.REGIAO && (
                    <FormControl error={!!errors.regiao} style={{ width: '100%', margin: "0.7rem 0" }}>
                        <Autocomplete
                            options={regioes}
                            {...register('regiao')}
                            value={watch('regiao') || ''}
                            getOptionLabel={(regiao) => {
                                if (regiao) {
                                    return `${regiao.descricao}`
                                }
                                return ""
                            }}
                            onChange={handleRegiaoChange}
                            noOptionsText={"Nenhuma região encontrada"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={handleBuscarRegioes}
                                    label="Região"
                                    error={!!errors.regiao}
                                />
                            )}
                            renderOption={(props, option) => {
                                if (option.cadastrar) {
                                    return (
                                        <MenuItem {...props} key={option.uuid} value={option}>
                                            <Button>Cadastrar {option?.descricao}</Button>
                                        </MenuItem>
                                    );
                                }

                                if (option) {
                                    return (
                                        <MenuItem {...props} key={option.uuid} value={option}>
                                            {option.descricao} - {real(option.preco)}
                                        </MenuItem>
                                    );
                                }

                                return null;
                            }}
                        />
                        {errors.regiao && <FormHelperText>{<>{errors.regiao?.message}</>}</FormHelperText>}
                    </FormControl>
                )}
                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        onClick={cancelar}
                    >
                        CANCELAR
                    </Button>
                    {loading ? (
                        <CircularProgress size={25} />
                    ) : (
                        <Button
                            variant="contained"
                            type="submit"
                        >
                            SALVAR
                        </Button>
                    )}
                </ButtonsContainer>
            </FormContainer>
            <FormularioRegioesEntrega
                formularioAberto={watch('regiao')?.cadastrar}
                fecharFormulario={() => {
                    if (watch('regiao').cadastrar) {
                        return setValue('regiao', undefined);
                    }
                    return setValue('regiao', {
                        ...watch('regiao'),
                        cadastrar: false
                    })
                }}
                cadastrarRegiao={handleCadastrarRegiao}
                valoresIniciais={watch('regiao')}
            />
        </PaperContainer>
    );
}