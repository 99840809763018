export enum TipoCobrancaEntrega {
    "REGIAO" = "REGIAO",
    "DISTANCIA" = "DISTANCIA"
}

export enum TipoCobrancaEntregaTitulo {
    "REGIAO" = "Cobrança por região",
    "DISTANCIA" = "Cobrança por distância"
}

export enum TipoCobrancaEntregaDescricao {
    "REGIAO" = "A cobrança por região permite que você cadastre regiões personalizadas.",
    "DISTANCIA" = "A cobrança por distância permite configurar faixas de distância e seus respectivos valores."
}