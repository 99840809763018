import * as yup from "yup";
import { validarCep } from "../../core/utils/validarCep";
import { UF } from "../../core/enum/UFs";

export const enderecoClienteRegiaoSchema =  yup.object().shape({
  rua: yup.string().required("Rua inválida"),
  cidade: yup.string().required("Cidade inválida"),
  bairro: yup.string().required("Bairro inválido"),
  numero: yup.string(),
  complemento: yup.string(),
  cep: yup.string().test('cep', 'CEP inválido', (value) => validarCep(value, false)),
  observacao: yup.string(),
  uf: yup.mixed<UF>()
  .oneOf(Object.values(UF) as UF[], "UF inválida")
  .required("UF é obrigatória"),
  regiao: yup.object({
    uuid: yup.string().required("UUID é obrigatório")
  }).required("Região é obrigatória")
  .typeError("Região inválida"), 
});

export const enderecoClienteDistanciaSchema =  yup.object().shape({
  rua: yup.string().required("Rua inválida"),
  cidade: yup.string().required("Cidade inválida"),
  bairro: yup.string().required("Bairro inválido"),
  numero: yup.string(),
  complemento: yup.string(),
  cep: yup.string().test('cep', 'CEP inválido', (value) => validarCep(value, false)),
  observacao: yup.string(),
  uf: yup.mixed<UF>()
  .oneOf(Object.values(UF) as UF[], "UF inválida")
  .required("UF é obrigatória")
});
  