import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IRegiao } from "../../core/interfaces/IRegiao";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { useFetch } from "../../core/hooks/useFetch";
import { IConfiguracaoPedidos } from "../interface/IConfiguracaoPedidos";
import { ApiFactory } from "../../core/config/ApiFactory";
import { IEndereco } from "../../core/interfaces/IEndereco";
import { TipoCobrancaEntrega } from "../enum/TipoCobrancaEntrega";
import { IDistancia } from "../interface/IDistancia";

interface IConfiguracoesPedidoContextProps {
    loading: boolean;
    configuracao: IConfiguracaoPedidos | undefined | null;
    alterarNumeroMesas: (numero: number) => Promise<boolean>
    alterarTipoCobrancaEntrega: (tipo: TipoCobrancaEntrega) => Promise<boolean>
    excluirRegiao: (regiaoUuid: string) => Promise<boolean>
    excluirDistancia: (distanciaUuid: string) => Promise<boolean>
    cadastrarRegiao: (regiao: IRegiao) => Promise<IRegiao | null>
    cadastrarDistancia: (distancia: IDistancia) => Promise<IDistancia | null>
    salvarMetodosPagamento: (metodosPagamento: string[]) => Promise<boolean>
    editarRegiao: (regiao: IRegiao) => Promise<boolean>
    editarDistancia: (distancia: IDistancia) => Promise<boolean>
    handleRefresh: () => void;
    listarRegioes: () => Promise<IRegiao[]>
}

export const useConfiguracoesPedidoContext = () => {
    return useContext(ConfiguracoesPedidoContext)
}
export const ConfiguracoesPedidoContext = createContext({} as IConfiguracoesPedidoContextProps)

export const ConfiguracoesPedidoProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const snackbar = useSnackbar();
    const apiConfiguracoes = useMemo(() => ApiFactory.getApi("MS_PEDIDOS"), []);
    const { data: configuracao, isLoading, handleRefresh, mutate } = useFetch<IConfiguracaoPedidos>(apiConfiguracoes, "/configuracoes");

    useEffect(() => setLoading(isLoading), [isLoading])

    const alterarNumeroMesas = useCallback(async (numero: number) => {
        if(typeof numero != "number") {
            snackbar(`Numero inválido`, { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.put(`/configuracoes/numero-mesas`, {
                numeroMesas: numero
            });
            if(data) {
                handleRefresh();
                setLoading(false);
                snackbar(`Número de mesas atualizado`, { severity: "success" })
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao atualizar o número de mesas`, { severity: "error" })
            return false;
        }

        return false;
    }, [])

    const excluirRegiao = useCallback(async (regiaoUuid: string) => {
        if(!regiaoUuid) {
            snackbar(`Região inválida`, { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.delete(`/configuracoes/regiao/${regiaoUuid}`);
            if(data) {
                setLoading(false);
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }

        return false;
    }, [])


    const excluirDistancia = useCallback(async (distanciaUuid: string) => {
        if(!distanciaUuid) {
            snackbar(`Distância inválida`, { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.delete(`/configuracoes/distancia/${distanciaUuid}`);
            if(data) {
                setLoading(false);
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            return false;
        }

        return false;
    }, [])

    const listarRegioes = useCallback(async () => {
        try {
            const { data } = await apiConfiguracoes.get("/configuracoes");
            return data.regioesEntrega
        } catch (error) {
            return [];
        }
    }, [])

    const cadastrarRegiao = useCallback(async (regiao: IRegiao) => {
        if(!regiao) {
            snackbar(`Região inválida`, { severity: "error" })
            return null;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.post(`/configuracoes/regiao`, regiao);
            if(data) {
                setLoading(false);
                snackbar(`Região cadastrada com sucesso`, { severity: "success" })
                handleRefresh();
                return data.regiao;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao cadastrar a região`, { severity: "error" })
            return null;
        }

        return null;
    }, [])

    const cadastrarDistancia = useCallback(async (distancia: IDistancia) => {
        if(!distancia) {
            snackbar(`Distância inválida`, { severity: "error" })
            return null;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.post(`/configuracoes/distancia`, distancia);
            if(data) {
                setLoading(false);
                snackbar(`Distância cadastrada com sucesso`, { severity: "success" })
                handleRefresh();
                return data.distancia;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao cadastrar a distância`, { severity: "error" })
            return null;
        }

        return null;
    }, [])

    const editarRegiao = useCallback(async (regiao: IRegiao) => {
        if(!regiao) {
            snackbar(`Região inválida`, { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.put(`/configuracoes/regiao/${regiao.uuid}`, {
                descricao: regiao.descricao,
                preco: regiao.preco
            });
            if(data) {
                setLoading(false);
                snackbar(`Região editada com sucesso`, { severity: "success" })
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao editar a região`, { severity: "error" })
            return false;
        }

        return false;
    }, [])

    const editarDistancia = useCallback(async (distancia: IDistancia) => {
        if(!distancia) {
            snackbar(`Distância inválida`, { severity: "error" })
            return false;
        }

        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.put(`/configuracoes/distancia/${distancia.uuid}`, {
                inicio: distancia.inicio,
                fim: distancia.fim,
                valor: distancia.valor
            });
            if(data) {
                setLoading(false);
                snackbar(`Distância editada com sucesso`, { severity: "success" })
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao editar a distância`, { severity: "error" })
            return false;
        }

        return false;
    }, [])

    const alterarTipoCobrancaEntrega = useCallback(async (tipo: TipoCobrancaEntrega) => {
        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.put(`/configuracoes/tipo-cobranca-entrega`, {
                tipo
            });
            if(data) {
                handleRefresh();
                setLoading(false);
                snackbar(`Tipo de cobrança atualizado`, { severity: "success" })
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao atualizar o tipo de cobrança`, { severity: "error" })
            return false;
        }

        return false;
    }, [])


    const salvarMetodosPagamento = useCallback(async (metodosPagamento: string[]) => {
        setLoading(true);
        try {
            const { data } = await apiConfiguracoes.put(`/configuracoes/metodos-pagamento`, { metodosPagamento });
            if(data) {
                setLoading(false);
                handleRefresh();
                return true;
            }
        } catch (error) {
            setLoading(false);
            snackbar(`Ocorreu um erro ao salvar os métodos de pagamento`, { severity: "error" })
            return false;
        }

        return false;
    }, [])

    return (
        <ConfiguracoesPedidoContext.Provider value={{
            salvarMetodosPagamento,
            alterarNumeroMesas,
            cadastrarRegiao,
            editarRegiao,
            configuracao,
            excluirRegiao,
            loading,
            handleRefresh,
            listarRegioes,
            alterarTipoCobrancaEntrega,
            cadastrarDistancia,
            editarDistancia,
            excluirDistancia
        }}>
            {children}
        </ConfiguracoesPedidoContext.Provider>
    );
} 