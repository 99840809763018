import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Drawer,
  Autocomplete,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  RadioGroup,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLetras } from "../../../core/hooks/useLetras";
import { PageContainer, ToolbarPaper } from "./styles";
import { useAutenticacaoContext } from "../../../core/contexts/AutenticacaoContext";
import { ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { v4 } from "uuid";
import {
  TipoMarketplace,
  TipoMarketplaceCor,
  TipoMarketplaceTitulo,
} from "../../../marketplace/enum/TipoMarketplace";
import { useSnackbar } from "../../../core/contexts/SnackbarContext";
import {
  TipoFluxo,
  TipoFluxoComanda,
  TipoFluxoComandaTitulo,
  TipoFluxoTitulo,
} from "../../../produtos/enum/TipoFluxo";
import DeleteIcon from "@mui/icons-material/Delete";
import { LayoutFlow } from "../../componentes/LayoutFlow";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ReceiptIcon from "@mui/icons-material/Receipt";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  ArrowBackIosNew,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import { useFetchCategorias } from "../../../produtos/hooks/useFetchCategorias";
import { useMarketplaces } from "../../../marketplace/hooks/useMarketplaces";
import { IMarketplace } from "../../../marketplace/interfaces/IMarketplace";
import { useFetchPerfis } from "../../../perfis/hooks/useFetchPerfis";
import { useConfiguracoesCardapioContext } from "../../../produtos/contexts/ConfiguracoesCardapioContext";
import { RadioCard } from "../../../core/components/RadioCard";

const getNodeStyle = (
  backgroundColor: string,
  label: string,
  extraStyles = {}
) => ({
  backgroundColor,
  color: "white",
  borderColor: "white",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  textOverflow: "ellipsis",
  overflow: "hidden",
  minHeight: Math.max(40, label.length * 2),
  height: label.length > 15 ? "fit-content" : "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...extraStyles,
});

const getNodeLabel = (label: string, type: string) => {
  const iconStyle = {
    marginRight: "8px",
    fontSize: "20px",
  };

  switch (type) {
    case "input":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccountTreeIcon style={iconStyle} />
          {label}
        </div>
      );
    case "output":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StorefrontIcon style={iconStyle} />
          {label}
        </div>
      );
    default:
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReceiptIcon style={iconStyle} />
          {label}
        </div>
      );
  }
};

const createNode = (
  id: string,
  position: any,
  style: any,
  data: any,
  type = ""
) => ({
  id,
  position,
  style,
  type,
  data: {
    ...data,
    label: getNodeLabel(data.label, type),
  },
  sourcePosition: "right",
  targetPosition: "left",
  draggable: true,
});

const createEdge = (
  source: string,
  target: string,
  type: string,
  stroke: string
) => {
  return {
    id: `e${source}-${target}`,
    source,
    target,
    type,
    style: {
      strokeWidth: 1.5,
      stroke,
    },
    animated: true,
    markerEnd: {
      type: "arrowclosed",
    },
  };
};

export const ConfiguracaoFluxoOperacao = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { titulo } = useLetras();
  const {
    configuracao,
    salvarConfiguracoesComandas,
    loading: loadingSalvar,
  } = useConfiguracoesCardapioContext();
  const { verificarAcessoFuncionalidade, perfil } = useAutenticacaoContext();
  const { categorias } = useFetchCategorias();
  const { perfis: perfisFetch } = useFetchPerfis();
  const { listar: listarMarketplaces } = useMarketplaces();

  const [openNovaConfiguracao, setOpenNovaConfiguracao] = useState(false);
  const [openNovaComanda, setOpenNovaComanda] = useState(false);
  const [tipoNovaConfiguracao, setTipoNovaConfiguracao] = useState<string>(
    TipoFluxo.simples
  );
  const [fluxoNovaConfiguracao, setFluxoNovaConfiguracao] =
    useState<string>("PDV");
  const [tipoNovaComanda, setTipoNovaComanda] = useState<string>(
    TipoFluxoComanda.nota_nao_fiscal
  );
  const [nodesT, setNodesT] = useState<any[]>([]);
  const [edgesT, setEdgesT] = useState<any[]>([]);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [fluxosContextos, setFluxosContextos] = useState<any[]>([]);
  const [selectedFluxoContexto, setSelectedFluxoContexto] = useState<any>(null);
  const [openExcluir, setOpenExcluir] = useState(false);
  const [openExcluirConfiguracao, setOpenExcluirConfiguracao] = useState(false);
  const [openExcluirFluxo, setOpenExcluirFluxo] = useState(false);
  const [forceLayout, setForceLayout] = useState(false);
  const [forceFitView, setForceFitView] = useState(false);
  const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([]);
  const [openAlterarFluxo, setOpenAlterarFluxo] = useState(false);
  const [openAlterarTipoFluxo, setOpenAlterarTipoFluxo] = useState(false);
  const [alterarTipoFluxo, setAlterarTipoFluxo] = useState<string>("COMPLETO");
  const [opcaoAlterarFluxo, setOpcaoAlterarFluxo] = useState<string>();

  const handleUpdateNodeComanda = (newData: any) => {
    if (!selectedNode) return;

    setSelectedNode({
      ...selectedNode,
      data: {
        ...selectedNode.data,
        comanda: {
          ...selectedNode.data.comanda,
          ...newData,
        },
      },
    });

    setNodesT((nodes) =>
      nodes.map((node) =>
        node.id === selectedNode.id
          ? {
              ...node,
              data: {
                ...node.data,
                comanda: { ...node.data.comanda, ...newData },
              },
            }
          : node
      )
    );

    orderLayout();
    orderFitView();
  };

  const handleDeleteNodeComanda = () => {
    if (!selectedNode?.id) return;

    const findChildNodes = (nodeId: string): string[] => {
      const childEdges = edgesT.filter((edge) => edge.source === nodeId);
      let childIds = childEdges.map((edge) => edge.target);

      childIds = childIds.filter((targetId) => {
        const targetNode = nodesT.find((node) => node.id === targetId);
        if (targetNode?.type === "output") {
          const incomingEdges = edgesT.filter(
            (edge) => edge.target === targetId
          );
          return incomingEdges.length <= 1;
        }
        return true;
      });

      const descendantIds = childIds.flatMap((id) => findChildNodes(id));
      return [...childIds, ...descendantIds];
    };

    const nodeIdsToDelete = [
      selectedNode.id,
      ...findChildNodes(selectedNode.id),
    ];

    setNodesT((nodes) =>
      nodes.filter((node) => !nodeIdsToDelete.includes(node.id))
    );
    setEdgesT((edges) =>
      edges.filter(
        (edge) =>
          !nodeIdsToDelete.includes(edge.source) &&
          !nodeIdsToDelete.includes(edge.target)
      )
    );
    setSelectedNode(null);
    setOpenExcluir(false);
    orderLayout();
    orderFitView();
  };

  const handleDeleteNodeConfiguracao = () => {
    if (!selectedNode?.data?.fluxo) return;

    const findChildNodes = (nodeId: string): string[] => {
      const childEdges = edgesT.filter((edge) => edge.source === nodeId);
      const childIds = childEdges.map((edge) => edge.target);
      const descendantIds = childIds.flatMap((id) => findChildNodes(id));
      return [...childIds, ...descendantIds];
    };

    const nodeIdsToDelete = [
      selectedNode.id,
      ...findChildNodes(selectedNode.id),
    ];

    setNodesT((nodes) =>
      nodes.filter((node) => !nodeIdsToDelete.includes(node.id))
    );
    setEdgesT((edges) =>
      edges.filter(
        (edge) =>
          !nodeIdsToDelete.includes(edge.source) &&
          !nodeIdsToDelete.includes(edge.target)
      )
    );

    setFluxosContextos((fluxos) =>
      fluxos.filter((fluxo) => fluxo.uuid !== selectedNode.data.fluxo.uuid)
    );

    const fluxoContexto = fluxosContextos.find(
      (fluxo) => fluxo.uuid !== selectedNode.data.fluxo.uuid
    );
    setSelectedFluxoContexto(fluxoContexto);

    setOpenExcluirConfiguracao(false);
    setSelectedNode(null);
  };

  const handleAddNovaConfiguracao = () => {
    const fluxoExistente = fluxosContextos.find(
      (fluxo) => fluxo.tipo === tipoNovaConfiguracao
    );

    if (fluxoExistente) {
      snackbar(
        "Já existe um fluxo cadastrado para este tipo de configuração.",
        { severity: "error" }
      );
      return;
    }

    const novaConfiguracao = {
      uuid: v4(),
      tipo: tipoNovaConfiguracao,
      fluxo: fluxoNovaConfiguracao,
      configuracoesComandas: [],
    };

    setFluxosContextos((prev) => [...prev, novaConfiguracao]);
    setSelectedFluxoContexto(novaConfiguracao);
    setOpenNovaConfiguracao(false);
  };

  const handleExcluirFluxo = () => {
    if (fluxosContextos.length <= 1) {
      snackbar(
        "Não é possível excluir o único fluxo. Pelo menos um fluxo deve permanecer.",
        { severity: "error" }
      );
      setOpenExcluirFluxo(false);
      return;
    }

    setFluxosContextos((prev) =>
      prev.filter((fluxo) => fluxo.uuid !== selectedFluxoContexto?.uuid)
    );
    const proximoFluxo = fluxosContextos.find(
      (fluxo) => fluxo.uuid !== selectedFluxoContexto?.uuid
    );
    setSelectedFluxoContexto(proximoFluxo);
    orderLayout();
    setOpenExcluirFluxo(false);
  };

  const handleSalvarConfiguracoes = async () => {
    const nodesPorPai = nodesT.reduce((acc: any, node: any) => {
      if (node.data?.comanda) {
        const parentId = edgesT.find((edge) => edge.target === node.id)?.source;
        if (parentId) {
          if (!acc[parentId]) acc[parentId] = [];
          acc[parentId].push(node);
        }
      }
      return acc;
    }, {});

    const fluxoAtualConvertido = nodesT
      .filter((node) => !node.data?.comanda)
      .map((node) => {
        const nodeData = node.data;
        const comandasFilhas = nodesPorPai[node.id] || [];

        return {
          uuid: nodeData?.fluxo?.uuid || v4(),
          tipo: nodeData?.fluxo?.tipo || TipoFluxo.simples,
          fluxo: nodeData?.fluxo?.fluxo || "PDV",
          configuracoesComandas: comandasFilhas.map((nodeFilho: any) => ({
            bucketName: "space-food-pedidos",
            objectName:
              nodeFilho.data?.comanda?.tipo === TipoFluxoComanda.nota_fiscal
                ? ""
                : nodeFilho.data?.comanda?.tipo ===
                  TipoFluxoComanda.nota_nao_fiscal
                ? "templates/nota_nao_fiscal.hbs"
                : nodeFilho.data?.comanda?.tipo ===
                  TipoFluxoComanda.comanda_acrescimo_produto_cozinha
                ? "templates/comanda_acrescimo_produto_cozinha.hbs"
                : "",
            gatilhos: nodeFilho.data?.comanda?.gatilhos || [],
            copias: nodeFilho.data?.comanda?.copias || 1,
            obrigatorio: nodeFilho.data?.comanda?.obrigatorio || false,
            configuracoesDestinatarios:
              nodeFilho.data?.comanda?.configuracoesDestinatarios || [],
            configuracoesProdutos:
              nodeFilho.data?.comanda?.configuracoesProdutos || [],
            marketplaces: nodeFilho.data?.comanda?.marketplaces || [],
            tipo: nodeFilho.data?.comanda?.tipo || "",
            uuid: nodeFilho.data?.comanda?.uuid || v4(),
          })),
        };
      })[0];

    const fluxosContextosConvertidos = fluxosContextos.map((fluxo) => {
      if (fluxo.uuid === selectedFluxoContexto?.uuid) {
        return fluxoAtualConvertido;
      }
      return fluxo;
    });

    await salvarConfiguracoesComandas({
      uuid: configuracao?.fluxoOperacao?.uuid || "",
      fluxosContextos: fluxosContextosConvertidos,
    });

    orderLayout();
    orderFitView();

    snackbar("Configurações salvas com sucesso", { severity: "success" });
  };

  const handleTipoNovaConfiguracaoChange = (e: any) => {
    setTipoNovaConfiguracao(e.target.value);
  };

  const handleFluxoNovaConfiguracaoChange = (e: any) => {
    setFluxoNovaConfiguracao(e.target.value);
  };

  const handleTipoNovaComandaChange = (e: any) => {
    setTipoNovaComanda(e.target.value);
  };

  const handleMarketplacesChange = (e: any) => {
    if (e.target.value?.length) {
      const selectedMarketplaces = e.target.value;

      const existingMarketplaces =
        selectedNode?.data?.comanda?.marketplaces || [];
      const updatedMarketplaces = selectedMarketplaces.map(
        (marketplace: any) => {
          const existingMarketplace = marketplaces.find(
            (m: any) => m.tipo === marketplace
          );
          return {
            tipo: marketplace,
            uuid: existingMarketplace?.uuid,
          };
        }
      );

      handleUpdateNodeComanda({
        marketplaces: updatedMarketplaces,
      });

      const oldMarketplaceNodes = nodesT.filter(
        (node) =>
          node.data?.marketplace &&
          node.data?.comanda?.uuid === selectedNode?.data?.comanda?.uuid
      );

      const oldMarketplaceEdges = edgesT.filter((edge) =>
        oldMarketplaceNodes.some((node) => node.id === edge.target)
      );

      const newMarketplaceNodes: any[] = [];
      const newMarketplaceEdges: any[] = [];

      selectedMarketplaces.forEach((marketplace: any, index: number) => {
        const existingGlobalMarketplaceNode = nodesT.find(
          (node) =>
            node.data?.marketplace?.tipo === marketplace &&
            !oldMarketplaceNodes.some((old) => old.id === node.id)
        );

        if (existingGlobalMarketplaceNode) {
          const edgeComandaMarketplace = createEdge(
            selectedNode.id,
            existingGlobalMarketplaceNode.id,
            "default",
            TipoMarketplaceCor[marketplace as keyof typeof TipoMarketplaceCor]
          );
          newMarketplaceEdges.push(edgeComandaMarketplace);
        } else {
          const existingMarketplaceNode = oldMarketplaceNodes.find(
            (node) => node.data?.marketplace?.tipo === marketplace
          );

          if (existingMarketplaceNode) {
            const reusedNode = createNode(
              existingMarketplaceNode.id,
              {
                x: existingMarketplaceNode.position.x,
                y: existingMarketplaceNode.position.y,
              },
              existingMarketplaceNode.style,
              {
                tipo: marketplace,
                uuid: existingMarketplaceNode.data.marketplace.uuid,
                fluxo: selectedNode.data.fluxo,
                comanda: selectedNode.data.comanda,
                marketplace: {
                  tipo: marketplace,
                  uuid: existingMarketplaceNode.data.marketplace.uuid,
                },
                label:
                  TipoMarketplaceTitulo[
                    marketplace as keyof typeof TipoMarketplaceTitulo
                  ],
              },
              "output"
            );
            newMarketplaceNodes.push(reusedNode);

            const edgeComandaMarketplace = createEdge(
              selectedNode.id,
              reusedNode.id,
              "default",
              TipoMarketplaceCor[marketplace as keyof typeof TipoMarketplaceCor]
            );
            newMarketplaceEdges.push(edgeComandaMarketplace);
          } else {
            const marketplaceUuid = marketplaces.find(mkt => mkt.tipo == marketplace)?.uuid;

            const newMarketplace = {
              uuid: marketplaceUuid ?? v4(),
              tipo: marketplace,
            };

            const newNode = createNode(
              `${selectedNode.data.comanda.uuid}-${newMarketplace.uuid}`,
              {
                x: selectedNode.position.x + 200 + index * 20,
                y: selectedNode.position.y - 50 + index * 100,
              },
              getNodeStyle(
                TipoMarketplaceCor[
                  marketplace as keyof typeof TipoMarketplaceCor
                ],
                TipoMarketplaceTitulo[
                  marketplace as keyof typeof TipoMarketplaceTitulo
                ],
                {
                  width: 150,
                  padding: 10,
                }
              ),
              {
                tipo: marketplace,
                uuid: newMarketplace.uuid,
                fluxo: selectedNode.data.fluxo,
                comanda: selectedNode.data.comanda,
                marketplace: newMarketplace,
                label:
                  TipoMarketplaceTitulo[
                    marketplace as keyof typeof TipoMarketplaceTitulo
                  ],
              },
              "output"
            );
            newMarketplaceNodes.push(newNode);

            const edgeComandaMarketplace = createEdge(
              selectedNode.id,
              newNode.id,
              "default",
              TipoMarketplaceCor[marketplace as keyof typeof TipoMarketplaceCor]
            );
            newMarketplaceEdges.push(edgeComandaMarketplace);
          }
        }
      });

      setNodesT((nodes) => [
        ...nodes.filter(
          (node) => !oldMarketplaceNodes.some((old) => old.id === node.id)
        ),
        ...newMarketplaceNodes,
      ]);

      setEdgesT((edges) => [
        ...edges.filter(
          (edge) => !oldMarketplaceEdges.some((old) => old.id === edge.id)
        ),
        ...newMarketplaceEdges,
      ]);

      orderLayout();
    }
  };

  const handleProdutosChange = (e: any) => {
    handleUpdateNodeComanda({
      configuracoesProdutos: [
        {
          tipo: e.target.value,
          uuid: v4(),
          parametros: [],
        },
      ],
    });
  };

  const handleDestinatariosChange = (e: any) => {
    handleUpdateNodeComanda({
      configuracoesDestinatarios: [
        {
          tipo: e.target.value,
          uuid: v4(),
          parametros: [],
        },
      ],
    });
  };

  const handleCategoriasProdutosChange = (
    _: React.SyntheticEvent<Element, Event>,
    categorias: string[]
  ) => {
    handleUpdateNodeComanda({
      configuracoesProdutos:
        selectedNode?.data?.comanda?.configuracoesProdutos?.map(
          (produto: any) =>
            produto.tipo === "CATEGORIA"
              ? {
                  ...produto,
                  parametros: categorias?.map(
                    (categoria: any) => categoria.uuid
                  ),
                }
              : produto
        ),
    });
  };

  const handlePerfisDestinatariosChange = (
    _: React.SyntheticEvent<Element, Event>,
    perfis: string[]
  ) => {
    const configuracaoDestinatario =
      selectedNode?.data?.comanda?.configuracoesDestinatarios[0];
    handleUpdateNodeComanda({
      configuracoesDestinatarios: [
        {
          ...configuracaoDestinatario,
          parametros: perfis?.map((perfil: any) => perfil.uuid),
        },
      ],
    });
  };

  const handleAlterarTipoFluxo = () => {
    setSelectedFluxoContexto({
      ...selectedFluxoContexto,
      fluxo: alterarTipoFluxo,
    });
    setFluxosContextos((prev) =>
      prev.map((fluxo) =>
        fluxo.uuid === selectedFluxoContexto?.uuid
          ? { ...fluxo, fluxo: alterarTipoFluxo }
          : fluxo
      )
    );
    orderLayout();
    setOpenAlterarTipoFluxo(false);
  };

  const handleAlterarFluxo = () => {
    if (opcaoAlterarFluxo == "ALTERAR_TIPO") {
      setOpenAlterarTipoFluxo(true);
      setOpenAlterarFluxo(false);
    }
    if (opcaoAlterarFluxo == "NOVA_COMANDA") {
      setOpenNovaComanda(true);
      setOpenAlterarFluxo(false);
    }
  };

  const handleCopiasChange = (e: any) => {
    handleUpdateNodeComanda({ copias: Number(e.target.value) });
  };

  const handleGatilhosChange = (e: any) => {
    handleUpdateNodeComanda({ gatilhos: [e.target.value] });
  };

  const handleObjectChange = (_: any) => {
    // Removido para não permitir alteração
  };

  const handleBucketChange = (_: any) => {
    // Removido para não permitir alteração
  };

  const handleObrigatorioChange = (e: any) => {
    handleUpdateNodeComanda({ obrigatorio: e.target.checked });
  };

  const handleAddTiposComandas = (e: any) => {
    const novaComanda = {
      tipo: tipoNovaComanda,
      uuid: v4(),
      label:
        TipoFluxoComandaTitulo[
          tipoNovaComanda as keyof typeof TipoFluxoComandaTitulo
        ],
      gatilhos: ["BOTAO_CONCLUIR"],
      copias: 1,
      obrigatorio: false,
      configuracoesProdutos: [
        {
          tipo: "TODOS",
          uuid: v4(),
          parametros: [],
        },
      ],
      configuracoesDestinatarios: [
        {
          tipo: "TODOS",
          uuid: v4(),
          parametros: [],
        },
      ],
      marketplaces: [
        {
          tipo: TipoMarketplace.PDV,
          uuid: v4(),
        },
      ],
    };

    const limiteComandas = {
      [TipoFluxoComanda.comanda_acrescimo_produto_cozinha]: 5,
      [TipoFluxoComanda.nota_nao_fiscal]: 5,
      [TipoFluxoComanda.nota_fiscal]: 1,
    };

    const quantidadeAtual = nodesT.filter(
      (node) => node?.data?.tipo === tipoNovaComanda
    ).length;

    if (
      quantidadeAtual >=
      limiteComandas[tipoNovaComanda as keyof typeof limiteComandas]
    ) {
      snackbar(
        `Limite de ${
          limiteComandas[tipoNovaComanda as keyof typeof limiteComandas]
        } configurações de comanda para ${
          TipoFluxoComandaTitulo[
            tipoNovaComanda as keyof typeof TipoFluxoComandaTitulo
          ]
        } atingido.`,
        { severity: "error" }
      );
      return;
    }

    const comandaX = selectedNode.position.x + 200;
    const comandaY = selectedNode.position.y + 100;

    const comandaNode = createNode(
      novaComanda.uuid,
      { x: comandaX, y: comandaY },
      getNodeStyle("#FF9800", novaComanda.tipo),
      {
        tipo: novaComanda.tipo,
        uuid: novaComanda.uuid,
        fluxo: selectedNode.data.fluxo,
        comanda: novaComanda,
        label:
          TipoFluxoComandaTitulo[
            novaComanda.tipo as keyof typeof TipoFluxoComandaTitulo
          ],
      }
    );

    const existingMarketplaceNode = nodesT.find(
      (node) => node.data?.marketplace?.tipo === TipoMarketplace.PDV
    );

    let marketplaceNode;
    let newNodes = [comandaNode];

    if (existingMarketplaceNode) {
      marketplaceNode = existingMarketplaceNode;
    } else {
      const marketplaceX = comandaX + 200;
      const marketplaceY = comandaY;

      marketplaceNode = createNode(
        `${novaComanda.uuid}-${novaComanda.marketplaces[0].uuid}`,
        { x: marketplaceX, y: marketplaceY },
        getNodeStyle(
          TipoMarketplaceCor[
            novaComanda.marketplaces[0].tipo as keyof typeof TipoMarketplaceCor
          ],
          TipoMarketplaceTitulo[
            novaComanda.marketplaces[0]
              .tipo as keyof typeof TipoMarketplaceTitulo
          ],
          {
            width: 150,
            padding: 10,
          }
        ),
        {
          tipo: novaComanda.marketplaces[0].tipo,
          uuid: novaComanda.marketplaces[0].uuid,
          fluxo: selectedNode.data.fluxo,
          comanda: novaComanda,
          marketplace: novaComanda.marketplaces[0],
          label:
            TipoMarketplaceTitulo[
              novaComanda.marketplaces[0]
                .tipo as keyof typeof TipoMarketplaceTitulo
            ],
        },
        "output"
      );

      newNodes.push(marketplaceNode);
    }

    setNodesT((nodes) => [...nodes, ...newNodes]);

    const edgeFluxoComanda = createEdge(
      selectedNode.id,
      novaComanda.uuid,
      "default",
      "#FF9800"
    );

    const edgeComandaMarketplace = createEdge(
      novaComanda.uuid,
      marketplaceNode.id,
      "default",
      TipoMarketplaceCor[
        novaComanda.marketplaces[0].tipo as keyof typeof TipoMarketplaceCor
      ]
    );

    setEdgesT((edges) => [...edges, edgeFluxoComanda, edgeComandaMarketplace]);

    setOpenNovaComanda(false);
    setSelectedNode(null);

    orderLayout();
  };

  useEffect(() => {
    if (configuracao?.fluxoOperacao?.fluxosContextos.length) {
      setFluxosContextos(configuracao.fluxoOperacao.fluxosContextos);
      if (!selectedFluxoContexto) {
        setSelectedFluxoContexto(configuracao.fluxoOperacao.fluxosContextos[0]);
        orderLayout();
      }
    }
  }, [configuracao]);

  useEffect(() => {
    if (selectedFluxoContexto) {
      const nodesFromConfig: any[] = [];
      const nodesFromComandas: any[] = [];
      const nodesFromMarketplaces: any[] = [];
      const edgesFromComandas: any[] = [];
      const edgesFromMarketplaces: any[] = [];

      nodesFromConfig.push(
        createNode(
          selectedFluxoContexto.uuid,
          { x: 0, y: 10 },
          getNodeStyle("#4CAF50", titulo(selectedFluxoContexto.tipo)),
          {
            label: titulo(selectedFluxoContexto.tipo),
            tipo: selectedFluxoContexto.tipo,
            uuid: selectedFluxoContexto.uuid,
            fluxo: selectedFluxoContexto,
          },
          "input"
        )
      );

      selectedFluxoContexto.configuracoesComandas.forEach(
        (comanda: any, comandaIndex: number) => {
          const comandaX = 250;
          const comandaY = 120 * comandaIndex + 100;

          const comandaNode = createNode(
            comanda.uuid,
            { x: comandaX, y: comandaY },
            getNodeStyle("#FF9800", comanda.tipo),
            {
              label:
                TipoFluxoComandaTitulo[
                  comanda.tipo as keyof typeof TipoFluxoComandaTitulo
                ],
              fluxo: selectedFluxoContexto,
              tipo: comanda.tipo,
              uuid: comanda.uuid,
              comanda,
            }
          );

          nodesFromComandas.push(comandaNode);

          const edgeFluxoComanda = createEdge(
            selectedFluxoContexto.uuid,
            comanda.uuid,
            "default",
            "#FF9800"
          );
          edgesFromComandas.push(edgeFluxoComanda);

          comanda.marketplaces.forEach(
            (marketplace: any, marketplaceIndex: number) => {
              const marketplaceX = comandaX + 200 + marketplaceIndex * 20;
              const marketplaceY = comandaY - 30 + marketplaceIndex * 80;

              const marketplaceNodeId = `${comanda.uuid}-${marketplace.uuid}`;
              const marketplaceType = marketplace?.tipo || "PDV";

              const existingMarketplaceNode = nodesFromMarketplaces.find(
                (node) => node.data.marketplace.tipo === marketplaceType
              );

              if (existingMarketplaceNode) {
                const edgeComandaMarketplace = createEdge(
                  comanda.uuid,
                  existingMarketplaceNode.id,
                  "default",
                  TipoMarketplaceCor[
                    marketplaceType as keyof typeof TipoMarketplaceCor
                  ]
                );
                edgesFromMarketplaces.push(edgeComandaMarketplace);
              } else {
                const marketplaceNode = createNode(
                  marketplaceNodeId,
                  { x: marketplaceX, y: marketplaceY },
                  getNodeStyle(
                    TipoMarketplaceCor[
                      marketplaceType as keyof typeof TipoMarketplaceCor
                    ],
                    TipoMarketplaceTitulo[
                      marketplaceType as keyof typeof TipoMarketplaceTitulo
                    ],
                    {
                      width: 150,
                      padding: 10,
                    }
                  ),
                  {
                    label:
                      TipoMarketplaceTitulo[
                        marketplaceType as keyof typeof TipoMarketplaceTitulo
                      ],
                    fluxo: selectedFluxoContexto,
                    comanda,
                    marketplace,
                  },
                  "output"
                );

                nodesFromMarketplaces.push(marketplaceNode);

                const edgeComandaMarketplace = createEdge(
                  comanda.uuid,
                  marketplaceNodeId,
                  "default",
                  TipoMarketplaceCor[
                    marketplaceType as keyof typeof TipoMarketplaceCor
                  ]
                );
                edgesFromMarketplaces.push(edgeComandaMarketplace);
              }
            }
          );
        }
      );

      setNodesT([
        ...nodesFromConfig,
        ...nodesFromComandas,
        ...nodesFromMarketplaces,
      ]);
      setEdgesT([...edgesFromComandas, ...edgesFromMarketplaces]);
    }
  }, [selectedFluxoContexto]);

  useEffect(() => {
    if (!selectedNode) return;

    if (!selectedNode?.data?.comanda) {
      setOpenAlterarFluxo(true);
    }
  }, [selectedNode]);

  useEffect(() => {
    const handleScrollLock = (isLocked: boolean) => {
      if (isLocked) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    handleScrollLock(true);

    return () => handleScrollLock(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const marketplacesFetch = await listarMarketplaces();
      setMarketplaces(marketplacesFetch);
    };
    fetchData();

    if (perfil && !verificarAcessoFuncionalidade("Fluxo de trabalho")) {
      navigate("/nao-autorizado");
    }
  }, []);

  function orderLayout() {
    setTimeout(() => {
      setForceLayout(true);
      setTimeout(() => {
        setForceLayout(false);
      }, 500);
    }, 500);
  }

  function orderFitView() {
    setTimeout(() => {
      setForceFitView(true);
      setTimeout(() => {
        setForceFitView(false);
      }, 500);
    }, 500);
  }

  return (
    <>
      <ToolbarPaper>
        <FormControl>
          <InputLabel>Selecione o Fluxo</InputLabel>
          <Select
            label="Selecione o Fluxo"
            value={selectedFluxoContexto?.uuid || ""}
            onChange={(e) => {
              const selected = fluxosContextos.find(
                (f) => f.uuid === e.target.value
              );
              setSelectedFluxoContexto(selected);
              setSelectedNode(null);
              orderLayout();
              orderFitView();
            }}
            size="small"
          >
            {fluxosContextos.map((fluxo) => (
              <MenuItem key={fluxo.uuid} value={fluxo.uuid}>
                {titulo(fluxo.tipo)} - {fluxo.fluxo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="outlined" onClick={orderLayout}>
          Organizar
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setOpenExcluirFluxo(true)}
        >
          Excluir fluxo
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={() => setOpenNovaConfiguracao(true)}
        >
          Adicionar novo fluxo
        </Button>
        <Button
          variant="contained"
          onClick={handleSalvarConfiguracoes}
          disabled={loadingSalvar}
        >
          {loadingSalvar ? (
            <CircularProgress size={20} sx={{ color: "white" }} />
          ) : (
            "Salvar"
          )}
        </Button>
      </ToolbarPaper>
      <PageContainer>
        <Dialog
          open={openNovaConfiguracao}
          onClose={() => setOpenNovaConfiguracao(false)}
        >
          <DialogTitle>Adicionar novo fluxo</DialogTitle>
          <DialogContent>
            <Box sx={{ marginBottom: 2 }}>
              {fluxoNovaConfiguracao === "COMPLETO" ? (
                <Alert severity="info" sx={{ marginTop: 1 }}>
                  <AlertTitle>Como funciona o fluxo completo?</AlertTitle>
                  No modo COMPLETO, os pedidos do PDV entram na mesma esteira de
                  processamento dos pedidos de marketplaces como iFood e
                  Cardápio Digital. Isso permite um fluxo unificado, onde o
                  pedido passa por todas as etapas, como preparo e entrega,
                  garantindo controle total do processo.
                </Alert>
              ) : fluxoNovaConfiguracao === "PDV" ? (
                <Alert severity="info" sx={{ marginTop: 1 }}>
                  <AlertTitle>Como funciona o fluxo PDV?</AlertTitle>
                  No modo PDV, os pedidos realizados no ponto de venda são
                  automaticamente concluídos no momento da criação. Esse fluxo é
                  ideal para operações rápidas, sem necessidade de etapas
                  adicionais como preparo ou entrega.
                </Alert>
              ) : null}
            </Box>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
              <InputLabel shrink>Tipo</InputLabel>
              <Select
                label="Tipo"
                size="small"
                value={tipoNovaConfiguracao}
                onChange={handleTipoNovaConfiguracaoChange}
              >
                <MenuItem value={TipoFluxo.simples}>Simples</MenuItem>
                <MenuItem value={TipoFluxo.delivery}>Delivery</MenuItem>
                <MenuItem value={TipoFluxo.mesa}>Mesa</MenuItem>
                <MenuItem value={TipoFluxo.retirada}>Retirada</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel shrink>Fluxo</InputLabel>
              <Select
                label="Fluxo"
                size="small"
                value={fluxoNovaConfiguracao}
                onChange={handleFluxoNovaConfiguracaoChange}
              >
                <MenuItem value={"PDV"}>PDV</MenuItem>
                <MenuItem value={"COMPLETO"}>Completo</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenNovaConfiguracao(false)}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button onClick={handleAddNovaConfiguracao} variant="contained">
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openExcluirFluxo}
          onClose={() => setOpenExcluirFluxo(false)}
        >
          <DialogTitle>Excluir fluxo</DialogTitle>
          <DialogContent>
            <Typography>
              Deseja realmente excluir o fluxo {selectedFluxoContexto?.tipo}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenExcluirFluxo(false)}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button onClick={handleExcluirFluxo} variant="contained">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlterarTipoFluxo}
          onClose={() => setOpenAlterarTipoFluxo(false)}
          fullWidth
        >
          <DialogTitle>Alterar fluxo</DialogTitle>
          <DialogContent>
            <Box sx={{ marginBottom: 2 }}>
              {alterarTipoFluxo === "COMPLETO" ? (
                <Alert severity="info" sx={{ marginTop: 1 }}>
                  <AlertTitle>Como funciona o fluxo completo?</AlertTitle>
                  No modo COMPLETO, os pedidos do PDV entram na mesma esteira de
                  processamento dos pedidos de marketplaces como iFood e
                  Cardápio Digital. Isso permite um fluxo unificado, onde o
                  pedido passa por todas as etapas, como preparo e entrega,
                  garantindo controle total do processo.
                </Alert>
              ) : alterarTipoFluxo === "PDV" ? (
                <Alert severity="info" sx={{ marginTop: 1 }}>
                  <AlertTitle>Como funciona o fluxo PDV?</AlertTitle>
                  No modo PDV, os pedidos realizados no ponto de venda são
                  automaticamente concluídos no momento da criação. Esse fluxo é
                  ideal para operações rápidas, sem necessidade de etapas
                  adicionais como preparo ou entrega.
                </Alert>
              ) : null}
            </Box>
            <FormControl
              sx={{
                marginTop: 1,
              }}
              fullWidth
            >
              <InputLabel>Tipo de fluxo</InputLabel>
              <Select
                label="Tipo de fluxo"
                value={alterarTipoFluxo}
                onChange={(e) => {
                  setAlterarTipoFluxo(e.target.value);
                }}
                size="small"
              >
                <MenuItem value="COMPLETO">Completo</MenuItem>
                <MenuItem value="PDV">PDV</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenAlterarTipoFluxo(false)}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button onClick={handleAlterarTipoFluxo} variant="contained">
              Alterar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlterarFluxo}
          onClose={() => setOpenAlterarFluxo(false)}
        >
          <DialogTitle>O que você deseja fazer?</DialogTitle>
          <DialogContent>
            <RadioGroup
              aria-labelledby="alterar-fluxo-alternativa"
              name="alterar-fluxo-alternativa"
              sx={{ gap: "1rem" }}
              onChange={(e, v) => setOpcaoAlterarFluxo(v)}
            >
              <RadioCard
                labelTitle={
                  <Typography variant="subtitle1" fontWeight={"bold"}>
                    Alterar o tipo do fluxo selecionado
                  </Typography>
                }
                labelDescription={
                  <Typography>
                    Realize a alteração do tipo do fluxo selecionado
                  </Typography>
                }
                fullWidth
                labelValue={"ALTERAR_TIPO"}
                selected={opcaoAlterarFluxo == "ALTERAR_TIPO"}
                onClick={() => setOpcaoAlterarFluxo("ALTERAR_TIPO")}
              />
              <RadioCard
                labelTitle={
                  <Typography variant="subtitle1" fontWeight={"bold"}>
                    Adicionar novo fluxo de comanda
                  </Typography>
                }
                labelDescription={
                  <Typography>
                    Adicione uma nova configuração de comanda
                  </Typography>
                }
                fullWidth
                labelValue={"NOVA_COMANDA"}
                selected={opcaoAlterarFluxo == "NOVA_COMANDA"}
                onClick={() => setOpcaoAlterarFluxo("NOVA_COMANDA")}
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenAlterarFluxo(false)}
              variant="outlined"
            >
              Fechar
            </Button>
            <Button
              onClick={handleAlterarFluxo}
              variant="contained"
              disabled={!opcaoAlterarFluxo}
            >
              Prosseguir
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openNovaComanda}
          onClose={() => setOpenNovaComanda(false)}
        >
          <DialogTitle>Adicionar nova configuração de comanda</DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              sx={{
                marginTop: 1,
              }}
            >
              <InputLabel shrink>Tipo de comanda</InputLabel>
              <Select
                label="Tipo de comanda"
                size="small"
                value={tipoNovaComanda}
                onChange={handleTipoNovaComandaChange}
              >
                <MenuItem value={TipoFluxoComanda.nota_nao_fiscal}>
                  Nota não fiscal
                </MenuItem>
                <MenuItem value={TipoFluxoComanda.nota_fiscal}>
                  Nota fiscal
                </MenuItem>
                <MenuItem
                  value={TipoFluxoComanda.comanda_acrescimo_produto_cozinha}
                >
                  Comanda de cozinha
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenNovaComanda(false)}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button onClick={handleAddTiposComandas} variant="contained">
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ padding: 0 }}>
          <ReactFlowProvider>
            <LayoutFlow
              nodesT={nodesT}
              edgesT={edgesT}
              setSelectedNode={setSelectedNode}
              forceLayout={forceLayout}
              forceFitView={forceFitView}
            />
          </ReactFlowProvider>
        </Box>
        <Drawer
          anchor="right"
          open={!!selectedNode?.data?.comanda}
          onClose={() => setSelectedNode(null)}
          sx={{
            "& .MuiDrawer-paper": {
              width: { xs: "100%", sm: "400px" },
              height: "100%",
              paddingTop: "3rem",
              boxSizing: "border-box",
            },
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "#FF9800",
                color: "white",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  <ArrowBackIosNew
                    fontSize="small"
                    onClick={() => setSelectedNode(null)}
                    style={{
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.opacity = "0.7";
                      e.currentTarget.style.transition = "opacity 0.3s";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.opacity = "1";
                      e.currentTarget.style.transition = "opacity 0.3s";
                    }}
                  />
                  <Typography>
                    {titulo(
                      TipoFluxoComandaTitulo[
                        selectedNode?.data?.comanda
                          ?.tipo as keyof typeof TipoFluxoComandaTitulo
                      ]
                    )}
                  </Typography>
                </div>
              </div>
            </div>

            {selectedNode?.data?.comanda && (
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #e0e0e0",
                }}
              >
                <FormControl sx={{ marginTop: 1 }} fullWidth>
                  <InputLabel id="gatilhos-select-label" shrink>
                    Gatilhos
                  </InputLabel>
                  <Select
                    size="small"
                    label="Gatilhos"
                    value={selectedNode?.data?.comanda?.gatilhos || ""}
                    onChange={handleGatilhosChange}
                  >
                    <MenuItem value="BOTAO_CONCLUIR">
                      {titulo("Botão Concluir")}
                    </MenuItem>
                    <MenuItem value="BOTAO_IMPRIMIR">
                      {titulo("Botão Imprimir")}
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Cópias"
                  size="small"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={selectedNode?.data?.comanda?.copias || 1}
                  onChange={handleCopiasChange}
                  style={{ width: "100%", margin: "1rem 0 0 0" }}
                />

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel htmlFor="serve-select">Destinatários</InputLabel>
                  <Select
                    label="Destinatários"
                    size="small"
                    value={
                      selectedNode?.data?.comanda?.configuracoesDestinatarios?.map(
                        (destinatario: any) => destinatario.tipo
                      ) || []
                    }
                    onChange={handleDestinatariosChange}
                  >
                    <MenuItem value="TODOS">Todos</MenuItem>
                    <MenuItem value="PERFIL">Perfil</MenuItem>
                  </Select>
                </FormControl>
                {selectedNode?.data?.comanda?.configuracoesDestinatarios[0]
                  ?.tipo === "PERFIL" && (
                  <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                    <Autocomplete
                      multiple
                      id="checkboxes-destinatarios"
                      size="small"
                      options={perfisFetch}
                      getOptionLabel={(option) => titulo(option?.nome)}
                      value={
                        selectedNode?.data?.comanda?.configuracoesDestinatarios
                          ?.find(
                            (destinatario: any) =>
                              destinatario.tipo === "PERFIL"
                          )
                          ?.parametros?.map((item: any) =>
                            perfisFetch.find(
                              (perfil: any) => perfil.uuid === item
                            )
                          ) || []
                      }
                      onChange={handlePerfisDestinatariosChange}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            checked={selected}
                          />
                          {titulo(option?.nome)}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Perfis"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormControl>
                )}

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel htmlFor="serve-select">Produtos</InputLabel>
                  <Select
                    label="Produtos"
                    size="small"
                    value={
                      selectedNode?.data?.comanda?.configuracoesProdutos?.map(
                        (produto: any) => produto.tipo
                      ) || []
                    }
                    onChange={handleProdutosChange}
                  >
                    <MenuItem value="TODOS">Todos</MenuItem>
                    <MenuItem value="CATEGORIA">Categoria</MenuItem>
                  </Select>
                </FormControl>
                {selectedNode?.data?.comanda?.configuracoesProdutos?.find(
                  (produto: any) => produto.tipo === "CATEGORIA"
                ) && (
                  <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                    <Autocomplete
                      multiple
                      id="checkboxes-destinatarios"
                      size="small"
                      options={categorias.filter((categoria: any) =>
                        categoria.contextos.includes(
                          selectedNode?.data?.fluxo?.tipo
                        )
                      )}
                      getOptionLabel={(option) => titulo(option.descricao)}
                      value={
                        selectedNode?.data?.comanda?.configuracoesProdutos
                          ?.find((produto: any) => produto.tipo === "CATEGORIA")
                          ?.parametros?.map((item: any) =>
                            categorias.find(
                              (categoria: any) => categoria.uuid === item
                            )
                          ) || []
                      }
                      onChange={handleCategoriasProdutosChange}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            checked={selected}
                          />
                          {titulo(option.descricao)}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Categorias"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormControl>
                )}

                <FormControl sx={{ marginTop: "1rem" }} fullWidth>
                  <InputLabel htmlFor="marketplace-select">
                    Marketplaces
                  </InputLabel>
                  <Select
                    multiple
                    label="Marketplaces"
                    size="small"
                    value={
                      selectedNode?.data?.comanda?.marketplaces?.map(
                        (marketplace: any) => marketplace.tipo
                      ) || []
                    }
                    onChange={handleMarketplacesChange}
                  >
                    {marketplaces?.map((marketplace) => (
                      <MenuItem
                        key={marketplace?.uuid}
                        value={marketplace?.tipo}
                      >
                        <Chip
                          sx={{
                            background: TipoMarketplaceCor[marketplace.tipo],
                            color: "white",
                          }}
                          label={TipoMarketplaceTitulo[marketplace.tipo]}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControlLabel
                      label="Obrigatório"
                      control={
                        <Checkbox
                          checked={
                            selectedNode?.data?.comanda?.obrigatorio || false
                          }
                          size="small"
                          onChange={handleObrigatorioChange}
                        />
                      }
                    />
                    <IconButton
                      color="error"
                      onClick={() => setOpenExcluir(true)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Box>

                <Dialog
                  open={openExcluir}
                  onClose={() => setOpenExcluir(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Excluir comanda{" "}
                    {
                      TipoFluxoComandaTitulo[
                        selectedNode?.data?.comanda
                          ?.tipo as keyof typeof TipoFluxoComandaTitulo
                      ]
                    }
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Realmente deseja excluir esta comanda?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenExcluir(false)}
                      variant="outlined"
                    >
                      Não
                    </Button>
                    <Button
                      onClick={handleDeleteNodeComanda}
                      variant="contained"
                      autoFocus
                    >
                      Sim
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}

            <Dialog
              open={openExcluirConfiguracao}
              onClose={() => setOpenExcluirConfiguracao(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Excluir configuração{" "}
                {
                  TipoFluxoTitulo[
                    selectedNode?.data?.fluxo
                      ?.tipo as keyof typeof TipoFluxoTitulo
                  ]
                }
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Realmente deseja excluir esta configuração?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenExcluirConfiguracao(false)}
                  variant="outlined"
                >
                  Não
                </Button>
                <Button
                  onClick={handleDeleteNodeConfiguracao}
                  variant="contained"
                  autoFocus
                >
                  Sim
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Drawer>
      </PageContainer>
    </>
  );
};
