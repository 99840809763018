import { Alert, Box, Button, CircularProgress, Grid, Link, Paper, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useConfiguracoesPedidoContext } from "../../../pedidos/contexts/ConfiguracoesPedidoContext";

interface AlterarNumeroMesasProps {
    numeroMesas?: number;
    alterarNumeroMesas: (numeroMesas: number) => Promise<boolean>
}

export const AlterarNumeroMesas = () => {
    const { configuracao, alterarNumeroMesas } = useConfiguracoesPedidoContext();
    const [numero, setNumero] = useState(configuracao?.numeroMesas ?? 0);
    const [loading, setLoading] = useState(false);

    const handleAlterarNumeroChange = (e: any, novoNumero: number | number[]) => {
        if (typeof novoNumero == "number") {
            setNumero(novoNumero)
        }
    }

    const handleAlterarNumero = async () => {
        setLoading(true);
        await alterarNumeroMesas(numero);
        setLoading(false);
    }

    useEffect(() => setNumero(configuracao?.numeroMesas ?? 0), [configuracao])

    return (
        <Box sx={{
            width: "100%",
            height: 600,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Paper
                sx={{
                    width: "100%",
                    p: "1rem",
                    height: 350,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    maxWidth: "600px"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem"
                    }}
                >
                    <Typography fontWeight={"bold"} variant="h5">
                        {numero} mesas
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem"
                    }}
                >
                    <Typography variant="subtitle2">
                        Selecione o número máximo de mesas que seu estabelecimento atende.<br />
                        Essa configuração reflete na quantidade de mesas disponíveis na página <Link href="/pedidos/operacao">Operação</Link>
                    </Typography>
                </Box>
                <Alert severity="warning" style={{ marginBottom: "1rem" }}>
                    Todos os pedidos em aberto serão concluídos
                </Alert>
                <Slider
                    aria-label="Mesas"
                    defaultValue={1}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={0}
                    max={100}
                    value={numero}
                    onChange={handleAlterarNumeroChange}
                    sx={{ maxWidth: "95%", margin: "0 auto" }}
                />
                <Button onClick={handleAlterarNumero}>
                    {loading ? (
                        <CircularProgress />
                    ) : "Atualizar"}
                </Button>
            </Paper>
        </Box>
    );
}