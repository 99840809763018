import * as yup from "yup";
import { validarMoedaReal } from "../../core/utils/validarMoedaReal";

export const distanciaEntregaSchema = yup.object().shape({
    inicio: yup.number()
        .typeError('O início deve ser um número')
        .required('O início é obrigatório')
        .min(0, 'O início não pode ser menor que 0'),
    fim: yup.number()
        .typeError('O fim deve ser um número')
        .required('O fim é obrigatório')
        .min(0, 'O fim não pode ser menor que 0')
        .test('fim-maior-inicio', 'O fim deve ser maior ou igual ao início', function (fim) {
            return fim >= this.parent.inicio;
        }),
    valor: yup.string().test('preco', 'Valor inválido', (valor) => {
        return !validarMoedaReal(valor, 0.00, undefined, true);
    }),
});