export function useMoeda() {
    function real(valor?: string | number) {
        if (valor === undefined || valor === null) {
            return "R$ 0,00";
        }
    
        const valueString = typeof valor === "string" ? valor.replace(',', '.') : valor.toString().replace(',', '.');
    
        const parsedValue = parseFloat(valueString);
    
        if (isNaN(parsedValue)) {
            return "R$ 0,00";
        }
    
        return parsedValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
    }

    function dolar(valor?: string | number) {
        if(!valor) {
            return "$ 0,00"
        }

        if(typeof valor == "string") {
            return parseFloat(valor).toLocaleString("pt-BR", { style: "currency", currency: "USD" });
        }

        return valor.toLocaleString("pt-BR", { style: "currency", currency: "USD" });
    }

    function number(valor?: string) {
        if (!valor) {
            return 0;
        }

        const numericPrice = valor?.replace(/[^0-9.,-]/g, '');
        const parsedNumber = parseFloat(numericPrice?.replace(',', '.'));
    
        return parseFloat(parsedNumber.toFixed(2));
    }

    return { real, number, dolar }
}