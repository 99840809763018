import { Box, CircularProgress, RadioGroup } from "@mui/material";
import { IMarketplace } from "../../../../interfaces/IMarketplace";
import { RadioCard } from "../../../../../core/components/RadioCard";
import { TipoCobrancaEntrega, TipoCobrancaEntregaDescricao, TipoCobrancaEntregaTitulo } from "../../../../../pedidos/enum/TipoCobrancaEntrega";
import { useState } from "react";
import { useMarketplaces } from "../../../../hooks/useMarketplaces";

interface TipoCobrancaEntregaContainerProps {
    marketplace: IMarketplace;
    refresh: () => void;
}

export const TipoCobrancaEntregaContainer = ({ marketplace, refresh }: TipoCobrancaEntregaContainerProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { salvar } = useMarketplaces();

    const handleTipoCobranca = async (tipo: TipoCobrancaEntrega) => {
        setLoading(true);
        await salvar({
            ...marketplace,
            configuracao: {
                ...marketplace.configuracao,
                tipoCobrancaEntrega: tipo
            }
        });
        setTimeout(() => {
            refresh();
            setLoading(false);
        }, 1500)
    }

    if(loading) {
        return (
            <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '5rem',
                marginBottom: '5rem',
            }}>
                <CircularProgress size={50} />
            </Box>
        );
    }

    return (
        <RadioGroup
            name="tipo-cobranca-entrega"
            row
            sx={{ display: "flex", justifyContent: "center", gap: '2rem' }}
            onChange={(e) => handleTipoCobranca(e.target?.value as TipoCobrancaEntrega)}
        >
            <RadioCard
                labelTitle={TipoCobrancaEntregaTitulo.DISTANCIA}
                labelDescription={TipoCobrancaEntregaDescricao.DISTANCIA}
                labelValue={TipoCobrancaEntrega.DISTANCIA}
                key={TipoCobrancaEntrega.DISTANCIA}
                selected={marketplace?.configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega.DISTANCIA}
                onClick={() => handleTipoCobranca(TipoCobrancaEntrega.DISTANCIA)}
            />
            {/* <RadioCard
                labelTitle={TipoCobrancaEntregaTitulo.REGIAO}
                labelDescription={TipoCobrancaEntregaDescricao.REGIAO}
                labelValue={TipoCobrancaEntrega.REGIAO}
                key={TipoCobrancaEntrega.REGIAO}
                selected={marketplace?.configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega.REGIAO}
                onClick={() => handleTipoCobranca(TipoCobrancaEntrega.REGIAO)}
            /> */}
        </RadioGroup>
    );
}