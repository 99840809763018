import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { useSnackbar } from "../../core/contexts/SnackbarContext";
import { MetodoPagamento } from "../../core/enum/MetodoPagamento";
import { TipoAnexoPedido } from "../enum/TipoAnexoPedido";
import { TipoPedido } from "../enum/TipoPedido";
import { useFetchPedidos } from "../hooks/useFetchPedidos";
import { usePedidos } from "../hooks/usePedidos";
import { IItemProduto } from "../interface/IItemProduto";
import { IPedido } from "../interface/IPedido";
import { IProdutoPedido } from "../interface/IProdutoPedido";
import { IConfiguracaoPedidos } from "../interface/IConfiguracaoPedidos";
import { useConfiguracoesPedidoContext } from "./ConfiguracoesPedidoContext";
import { TipoProduto } from "../../produtos/enum/TipoProduto";
import { StatusPedido, StatusPedidoDescricao } from "../enum/StatusPedido";
import { IEndereco } from "../../core/interfaces/IEndereco";
import { ICliente } from "../../clientes/interface/ICliente";
import moment from "moment";
import { format } from "path";
import { TipoItemProduto } from "../../produtos/enum/TipoItemProduto";
import { TipoPessoa } from "../pages/Operacao/containers/EmitirNotaFiscalModal";
import { useHotkeys } from "react-hotkeys-hook";
import { IDadosEmitirNotaFiscal } from "../interface/IDadosEmitirNotaFiscal";
import { validarCpf } from "../../core/utils/validarCpf";
import { validarCnpj } from "../../core/utils/validarCnpj";
import { IFluxoContexto } from "../interface/IFluxoContexto";
import { IConfiguracaoComanda } from "../interface/IConfiguracaoComanda";
import { TipoGatilhoComanda } from "../enum/TipoGatilhoComanda";
import { IProduto } from "../../produtos/interfaces/IProduto";
import { TipoMarketplace } from "../../marketplace/enum/TipoMarketplace";
import { IPagamentoPedido } from "../interface/IPagamentoPedido";
import { useEntregas } from "../hooks/useEntregas";
import { TipoCobrancaEntrega } from "../enum/TipoCobrancaEntrega";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { IComplementoItensPedido } from "../interface/IComplementoItensPedido";

interface IOperacaoContextProps {
    agendadoPara: moment.Moment | null;
    agendarHorario: (hora: moment.Moment | null) => void;
    configuracao: IConfiguracaoPedidos | null | undefined;
    cliente: ICliente | undefined;
    produtos: IProdutoPedido[];
    valorTotalProdutos: number;
    valorTotalPedido: number;
    pagamentos: IPagamentoPedido[];
    alterarPagamentos: (pagamentos?: IPagamentoPedido[] | undefined) => void;
    adicionarProduto: (produto: IProdutoPedido) => void;
    editarProduto: (produto: IProdutoPedido) => void;
    aumentarQuantidade: (produtoUuid: string) => Promise<void>;
    diminuirQuantidade: (produtoUuid: string) => Promise<void>;
    removerProduto: (produtoUuid: string) => Promise<void>;
    abrirPedido: (dadosPedido?: any) => Promise<IPedido>;
    fecharPedido: (dados?: IDadosEmitirNotaFiscal, pagamentos?: IPagamentoPedido[]) => Promise<void>
    loading: boolean;
    selecionarPedido: (uuid: string | undefined) => void;
    tipo: TipoPedido;
    alterarTipo: (tipo: TipoPedido) => void;
    pedidos: IPedido[];
    pedidoSelecionado?: string;
    valorTroco: number | undefined;
    valorRecebido: number | undefined;
    valorFrete: number;
    alterarEndereco: () => void;
    removerCliente: () => void;
    vincularEndereco: (endereco: IEndereco) => void;
    endereco: IEndereco | undefined;
    vincularCliente: (cliente: ICliente) => void;
    salvarPedido: () => void;
    cancelarPedido: () => void;
    resetarTudo: () => void;
    numeroTotalItens: number;
    numeroPedidoDiario?: number;
    switchNotaFiscal: boolean;
    modalNotaFiscal: boolean;
    handleModalNotaFiscal: () => void;
    handleSwitchNotaFiscal: () => void;
    selecionarProduto: (produto: IProdutoPedido) => void;
    produtosSelecionados: IProdutoPedido[];
    fluxoContexto?: IFluxoContexto;
    configuracaoNFCe?: IConfiguracaoComanda;
    gerarAnexosBotaoImprimirComandas: () => Promise<void>
    loadingVincularEndereco: boolean;
}

export const useOperacaoContext = () => {
    return useContext(OperacaoContext)
}
export const OperacaoContext = createContext({} as IOperacaoContextProps)

export type TipoDivisaoPagamento = "Quantidade de pessoas" | "Produtos selecionados" | "Valores selecionados";

export const OperacaoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const snackbar = useSnackbar();
    const [tipo, setTipo] = useState<TipoPedido>(TipoPedido.mesa);
    const [pedidoSelecionado, setPedidoSelecionado] = useState<string | undefined>(undefined);
    const [pagamentos, setPagamentos] = useState<IPagamentoPedido[]>([]);
    const [produtos, setProdutos] = useState<IProdutoPedido[]>([]);
    const [pedidoAberto, setPedidoAberto] = useState<string | undefined>(undefined)
    const { configuracao } = useConfiguracoesPedidoContext();
    const [endereco, setEndereco] = useState<IEndereco | undefined>(undefined);
    const [cliente, setCliente] = useState<ICliente | undefined>(undefined);
    const [agendadoPara, setAgendadoPara] = useState<moment.Moment | null>(null);
    const [numeroPedidoDiario, setNumeroPedidoDiario] = useState<undefined | number>(undefined);
    const [switchNotaFiscal, setSwitchNotaFiscal] = useState(false);
    const [modalNotaFiscal, setModalNotaFiscal] = useState(false);
    const [produtosSelecionados, setProdutosSelecionados] = useState<IProdutoPedido[]>([]);
    const [loadingVincularEndereco, setLoadingVincularEndereco] = useState(false);

    useHotkeys(['f8'], () => fecharPedido(), [
        tipo,
        pedidoSelecionado,
        pagamentos,
        produtos,
        pedidoAberto,
        configuracao,
        endereco,
        cliente,
        agendadoPara,
        numeroPedidoDiario,
        switchNotaFiscal,
        modalNotaFiscal,
    ]);

    useHotkeys(['f9'], () => salvarPedido(), [
        tipo,
        pedidoSelecionado,
        pagamentos,
        produtos,
        pedidoAberto,
        configuracao,
        endereco,
        cliente,
        agendadoPara,
        numeroPedidoDiario,
        switchNotaFiscal,
        modalNotaFiscal,
    ]);

    const handleModalNotaFiscal = () => {
        setModalNotaFiscal(!modalNotaFiscal);
    }

    const handleSwitchNotaFiscal = () => {
        setSwitchNotaFiscal(!switchNotaFiscal);
    }

    const valorFrete = useMemo(() => endereco?.regiao?.preco ?? 0, [endereco]);

    const urlFetch = useMemo(() => {
        if (tipo == TipoPedido.mesa) {
            return `?status=Em andamento&tipos=${TipoPedido.mesa}`;
        }

        if (tipo == TipoPedido.delivery) {
            return `?status=Em andamento&tipos=${TipoPedido.delivery}`;
        }

        if (tipo == TipoPedido.retirada) {
            return `?status=Em andamento&tipos=${TipoPedido.retirada}`;
        }

        if (tipo == TipoPedido.simples) {
            return `?status=Em andamento&tipos=${TipoPedido.simples}`;
        }
    }, [tipo]);

    const { pedidos, mutate, handleRefresh } = useFetchPedidos(urlFetch);

    const { estabelecimento } = useAutenticacaoContext();

    const {
        gerarPedido,
        loading,
        atualizarStatus,
        adicionarProduto: adicionarProdutoPedido,
        removerProduto: removerProdutoPedido,
        gerarAnexo: gerarAnexoPedido,
        atualizarPagamentos: atualizarPagamentosPedido,
        alterarEndereco: atualizarEndereco,
        emitirNotaFiscal: emitirNotaFiscalConsumidor,
    } = usePedidos();

    const { calcularFrete, loading: loadingEntregas} = useEntregas();

    const alterarTipo = (tipo: TipoPedido) => {
        setTipo(tipo);
        resetarTudo();
    }

    useEffect(() => {
        if (pedidoAberto && pedidos && pedidos.length) {
            const pedidoEncontrado = pedidos.find(pedidoProcurado => pedidoProcurado.uuid == pedidoAberto);
            if (pedidoEncontrado) {
                selecionarPedido(pedidoEncontrado.uuid);
            }
        }
    }, [pedidoAberto, pedidos])

    const cancelarPedido = async () => {
        const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid == pedidoSelecionado);

        if (!pedidoEncontrado) {
            return snackbar("Pedido não encontrado", { severity: "error" });
        }

        const { pedido } = await atualizarStatus({
            pedidoUuid: pedidoEncontrado.uuid, 
            status: StatusPedidoDescricao.Cancelado,
            pagamentos
        });
        if (pedido) {
            resetarTudo();
            handleRefresh();
            snackbar("Pedido cancelado com sucesso", { severity: "success" })
        }
    }

    const resetarProdutos = () => {
        setProdutos([]);
    }

    const resetarCheckout = () => {
        setAgendadoPara(null);
        setPagamentos([]);
        setCliente(undefined);
        setEndereco(undefined);
        setPedidoSelecionado(undefined);
        setNumeroPedidoDiario(undefined);
    }

    const resetarTudo = () => {
        resetarProdutos();
        resetarCheckout();
    }

    const editarProduto = async (produto: IProdutoPedido) => {
        await removerProduto(produto.uuid);
        await adicionarProduto(produto);
    }

    function precificarPorMaiorPreco(produto: IProduto, itens: IItemProduto[]) {
        let maiorPreco = 0;
        produto.sabores?.forEach((sabor) => {
            if (sabor.preco > maiorPreco) {
                maiorPreco = sabor.preco;
            }
        });
    
        const saboresAtualizados = produto.sabores?.map((sabor) => {
            if (sabor.preco === maiorPreco) {
                return { ...sabor };
            }
            return { ...sabor, preco: 0 };
        });
    
        saboresAtualizados?.forEach(sabor => {
            itens.push({
                descricao: sabor.descricao,
                preco: sabor.preco,
                quantidade: 1,
                tipo: TipoItemProduto.sabor,
                uuidReferencia: sabor.uuid
            });
        });
    }

    function precificarPorPrecoDividido(produto: IProduto, itens: IItemProduto[]) {
        const qtdSabores = produto.sabores?.length ?? 0;
        const somaPrecos = produto.sabores?.reduce((total, sabor) => total + sabor.preco, 0);
        const valorMedio = Math.ceil((somaPrecos ?? 0) / qtdSabores);
        const saboresAtualizados = produto.sabores?.map((sabor) => ({ ...sabor, preco: valorMedio / (produto.sabores?.length ?? 1) }));
    
        saboresAtualizados?.forEach(sabor => {
            itens.push({
                descricao: sabor.descricao,
                preco: sabor.preco,
                quantidade: 1,
                tipo: TipoItemProduto.sabor,
                uuidReferencia: sabor.uuid
            });
        });
    }
    
    function precificarPorPrecoMisto(produto: IProduto, itens: IItemProduto[]) {
        if ((produto.sabores?.length ?? 0) > 2) {
            precificarPorMaiorPreco(produto, itens);
        } else {
            precificarPorPrecoDividido(produto, itens);
        }
    }

    const tratarProduto = (produto: IProdutoPedido): IProdutoPedido | undefined => {
        if (produto.tipo == TipoProduto.pizza) {
            const itens: IItemProduto[] = [];

            if (produto.borda) {
                itens.push({
                    descricao: produto.borda.descricao,
                    preco: produto.borda.preco,
                    quantidade: 1,
                    tipo: TipoItemProduto.borda,
                    uuidReferencia: produto.borda.uuid
                })
            }

            if (produto.massa) {
                itens.push({
                    descricao: produto.massa.descricao,
                    preco: produto.massa.preco,
                    quantidade: 1,
                    tipo: TipoItemProduto.massa,
                    uuidReferencia: produto.massa.uuid
                })
            }

            if (produto.configuracao?.modeloPrecificacao === "maiorPreco") {
                precificarPorMaiorPreco(produto, itens);
            } else if (produto.configuracao?.modeloPrecificacao === "precoDividido") {
                precificarPorPrecoDividido(produto, itens);
            } else if (produto.configuracao?.modeloPrecificacao === "precoMisto") {
                precificarPorPrecoMisto(produto, itens);
            }

            if (produto.observacao) {
                itens.push({
                    descricao: produto.observacao,
                    preco: produto.valorAdicional ?? 0,
                    quantidade: 1,
                    tipo: TipoItemProduto.observacao
                })
            }

            return {
                ...produto,
                itens,
                quantidade: 1,
                uuid: v4(),
                tipoProdutoReferencia: produto.tipo
            }
        }

        if (produto.tipo == TipoProduto.preparado) {
            const itens: IItemProduto[] = [];
            const complementosItens: IComplementoItensPedido[] = [];

            produto.complementos?.map(complemento => {
                if(complemento?.itensSelecionados?.length) {
                    complementosItens.push({
                        descricao: complemento.descricao,
                        uuid: complemento.uuid,
                        itens: complemento.itensSelecionados?.map(item => item.uuid)
                    })

                    complemento.itensSelecionados?.map(item => {
                        itens.push({
                            descricao: item.descricao,
                            preco: item.preco,
                            quantidade: 1,
                            tipo: TipoItemProduto.item,
                            uuidReferencia: item.uuid
                        })
                    })
                }
            })

            if (produto.observacao) {
                itens.push({
                    descricao: produto.observacao,
                    preco: produto.valorAdicional ?? 0,
                    quantidade: 1,
                    tipo: TipoItemProduto.observacao
                })
            }

            return {
                ...produto,
                quantidade: 1,
                uuid: v4(),
                itens,
                tipoProdutoReferencia: produto.tipo,
                complementosItens
            }
        }

        if (produto.tipo == TipoProduto.industrializado) {
            return {
                ...produto,
                quantidade: 1,
                uuid: v4(),
                tipoProdutoReferencia: produto.tipo
            }
        }
    }

    useEffect(() => {
        if (pedidoSelecionado) {
            const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado);

            if (!pedidoEncontrado) {
                setPagamentos([]);
                return;
            }

            setPagamentos(pedidoEncontrado.pagamentos ?? []);
        }
    }, [pedidos, pedidoSelecionado])

    const valorTotalProdutos = useMemo(() => {
        let totalProdutos = 0;

        produtos.map(produto => {
            let totalProduto = (produto.preco ?? 0);

            produto.itens?.map(item => {
                totalProduto = totalProduto + ((item.preco ?? 0) * (item.quantidade ?? 1));
            })

            totalProdutos = totalProdutos + (totalProduto * (produto.quantidade ?? 1));
        })

        return totalProdutos;
    }, [produtos]);

    const valorTotalPedido = useMemo(() => {
        return (valorTotalProdutos ?? 0) + (valorFrete ?? 0);
    }, [valorTotalProdutos, valorFrete]);

    const numeroTotalItens = useMemo(() => {
        let total = 0;

        produtos.map(produto => {
            total += produto.quantidade ?? 1
        })

        return total
    }, [produtos, valorTotalPedido])

    const valorRecebido = useMemo(() => {
        let total = 0;

        const pagamentosDinheiro = pagamentos?.filter(pagamento => pagamento.metodoPagamento == MetodoPagamento.Dinheiro) ?? [];
        pagamentosDinheiro?.map(pagamento => {
            total = total + (pagamento.valorRecebido ?? 0);
        })        

        return total;
    }, [pagamentos]);
    const valorTroco = useMemo(() => valorRecebido ? (valorRecebido - valorTotalPedido) : 0, [valorTotalPedido, valorRecebido])

    const fluxoContexto = useMemo(() => {
        return configuracao?.fluxoOperacao?.fluxosContextos?.find(fluxo => fluxo.tipo === tipo);
    }, [configuracao, tipo])

    const configuracaoNFCe = useMemo(() => {
        return fluxoContexto?.configuracoesComandas.find(config => config.tipo == TipoAnexoPedido.nota_fiscal);
    }, [fluxoContexto])
    

    useEffect(() => {
        if(configuracaoNFCe?.obrigatorio == true) {
            setSwitchNotaFiscal(true);
        } else {
            setSwitchNotaFiscal(false);
        }
    }, [fluxoContexto, configuracaoNFCe])

    const alterarPagamentos = async (pagamentos?: IPagamentoPedido[]) => {
        if(!pagamentos) {
            pagamentos = [];
        }

        if (pedidoSelecionado) {
            const resultado = await atualizarPagamentosPedido(
                pedidoSelecionado,
                pagamentos
            );

            if (!resultado) {
                return snackbar("Ocorreu um erro ao atualizar o método de pagamento", { severity: "error" });
            }
            handleRefresh();
        }

        setPagamentos(pagamentos);
    }

    const adicionarProduto = async (produto: IProdutoPedido) => {
        if(pagamentos.length) {
            await alterarPagamentos([]);
            snackbar("O pagamento foi removido", { severity: "info" })
        }
        const produtoJaExiste = produtos.find(produtoCarrinho => produtoCarrinho.uuidProdutoReferencia === produto.uuidProdutoReferencia);

        if (
            produtoJaExiste &&
            produtoJaExiste.uuid &&
            produto.tipo == TipoProduto.industrializado
        ) {
            return aumentarQuantidade(produtoJaExiste.uuid);
        }

        if (
            produtoJaExiste &&
            produtoJaExiste.uuid &&
            produto.tipo == TipoProduto.preparado &&
            (
                produto.configuracao?.habilitarComplementos == false ||
                produto.configuracao?.habilitarObservacao == false
            )
        ) {
            return aumentarQuantidade(produtoJaExiste.uuid);
        }

        const produtoTratado = tratarProduto(produto);
        if (!produtoTratado) {
            return snackbar("Produto invalido", { severity: "error" })
        }

        setProdutos((produtosPedido) => [...produtosPedido, produtoTratado]);
        if (pedidoSelecionado) {
            const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado);

            if (pedidoEncontrado) {
                adicionarProdutoPedido(produtoTratado, pedidoSelecionado);
                handleRefresh();
            }
        }
        return;
    }

    const salvarPedido = async () => {
        const dados = {
            status: StatusPedidoDescricao["Em andamento"]
        } as IPedido
        const pedido = await abrirPedido(dados, true);
        if(pedido) {
            snackbar("Pedido salvo com sucesso", { severity: "success" })
        }
    }

    const selecionarPedido = (uuid: string | undefined) => {
        const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === uuid);
        setProdutosSelecionados([]);
        setProdutos(pedidoEncontrado?.produtos ?? []);
        setPedidoSelecionado(uuid);
        setPagamentos(pedidoEncontrado?.pagamentos ?? []);
        setPedidoAberto(undefined);
        setCliente(pedidoEncontrado?.cliente);
        setEndereco(pedidoEncontrado?.endereco);
        setNumeroPedidoDiario(pedidoEncontrado?.numeroPedidoDiario);

        if (pedidoEncontrado?.agendadoPara) {
            const data = moment().format("YYYY-MM-DD");
            setAgendadoPara(moment(`${data} ${pedidoEncontrado.agendadoPara}`));
        }
    }

    const limparPedido = () => {
        setProdutosSelecionados([]);
        setProdutos([]);
        setPedidoSelecionado(undefined);
        setPagamentos([]);
        setPedidoAberto(undefined);
        setCliente(undefined);
        setEndereco(undefined);
        setNumeroPedidoDiario(undefined);
        setAgendadoPara(null);
    }

    const abrirPedido = async (dadosPedido?: IPedido, limpar?: boolean) => {
        const produtosPedido = dadosPedido?.produtos ? dadosPedido?.produtos?.map(produto => {
            return tratarProduto(produto);
        }) : produtos;

        const dadosTratadosPedido = {
            tipo: dadosPedido?.tipo ?? tipo,
            produtos: produtosPedido,
            pagamentos: pagamentos,
            mesaId: dadosPedido?.mesaId,
            cliente: dadosPedido?.cliente ?? cliente,
            endereco: dadosPedido?.endereco ?? endereco,
            agendadoPara: dadosPedido?.agendadoPara ?? agendadoPara?.format("HH:mm"),
            status: dadosPedido?.status,
            marketplace: {
                tipo: TipoMarketplace.PDV
            }
        } as IPedido;

        const { pedido } = await gerarPedido(dadosTratadosPedido, tipo);

        if (pedido) {
            handleRefresh()
            if(!limpar) {
                setPedidoAberto(pedido.uuid);
            } else {
                setTimeout(() => limparPedido(), 1000)
            }
        }

        return pedido;
    }

    const fecharPedido = async (dadosEmitirNota?: IDadosEmitirNotaFiscal, pagamentosParametro?: IPagamentoPedido[]) => {
        if(switchNotaFiscal && !modalNotaFiscal) {
            return handleModalNotaFiscal();
        } 

        if(dadosEmitirNota && switchNotaFiscal) {
            if(dadosEmitirNota.tipoPessoa == "CPF" && !validarCpf(dadosEmitirNota.numeroPessoa, true)) {
                return snackbar("CPF inválido", { severity: "error" });
            }
    
            if(dadosEmitirNota.tipoPessoa == "CNPJ" && !validarCnpj(dadosEmitirNota.numeroPessoa, true)) {
                return snackbar("CNPJ inválido", { severity: "error" });
            }
        }

        const fluxo = fluxoContexto?.fluxo;
        
        if (pedidoSelecionado) {
            const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado)

            if (!pedidoEncontrado) {
                return snackbar("Pedido não iniciado", { severity: "error" });
            }

            let erroTroco = false;
            if(pagamentosParametro?.length) {
                pagamentosParametro.map(pagamento => {
                    if (pagamento.metodoPagamento == MetodoPagamento.Dinheiro && valorTroco < 0) {
                        erroTroco = true;
                    }
                })
            } else {
                pagamentos.map(pagamento => {
                    if (pagamento.metodoPagamento == MetodoPagamento.Dinheiro && valorTroco < 0) {
                        erroTroco = true;
                    }
                })
            }

            if(erroTroco) {
                return snackbar("Troco inválido", { severity: "error" });
            }

            const { pedido } = await atualizarStatus({
                pedidoUuid: pedidoEncontrado.uuid, 
                status: fluxo == "PDV" ? StatusPedidoDescricao.Concluído : StatusPedidoDescricao["Em preparo"], 
                pagamentos: pagamentosParametro ?? pagamentos
            });

            if (pedido) {
                const pedidoIndex = pedidos?.findIndex(pedidoProcurado => pedidoProcurado.uuid === pedido.uuid);
                const novosPedidos = [...pedidos];
                novosPedidos.splice(pedidoIndex, 1);
                mutate(novosPedidos);
                gerarAnexosBotaoFecharPedido(pedido, dadosEmitirNota);
                setPedidoSelecionado(undefined);
                setProdutos([]);
                setPagamentos([]);
                setCliente(undefined);
                setEndereco(undefined);
                setAgendadoPara(null);
                setNumeroPedidoDiario(undefined);
            }
        } else {
            if (!produtos.length) {
                return snackbar("Nenhum produto adicionado ao pedido", { severity: "error" })
            }

            const dadosPedido = {
                agendadoPara: agendadoPara?.format("HH:mm"),
                produtos: produtos,
                endereco,
                cliente,
                tipo,
                status: fluxo == "PDV" ? "Concluído" : "Em preparo",
                pagamentos: pagamentosParametro ?? pagamentos,
                marketplace: {
                    tipo: TipoMarketplace.PDV
                }
            } as IPedido;

            const resultado = await gerarPedido(dadosPedido, tipo);

            if (!resultado) {
                snackbar("Ocorreu um erro ao fechar o pedido", { severity: "error" })
                return;
            }

            gerarAnexosBotaoFecharPedido(resultado.pedido, dadosEmitirNota);
            snackbar("Pedido fechado com sucesso", { severity: "success" });

            setPedidoSelecionado(undefined);
            setProdutos([]);
            setPagamentos([]);
            setCliente(undefined);
            setNumeroPedidoDiario(undefined);
            setEndereco(undefined);
            setAgendadoPara(null);
        }
    }

    const gerarAnexosBotaoImprimirComandas = async () => {
        if (loading) {
            return;
        }

        if (!produtos || !produtos.length) {
            return snackbar("Nenhum produto no carrinho", { severity: "error" });
        }

        const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado);
        if (!pedidoEncontrado) {
            return snackbar("Pedido não encontrado", { severity: "error" });
        }

        const configuracoesComanda = fluxoContexto?.configuracoesComandas.filter(config => {
            if(config.gatilhos.includes(TipoGatilhoComanda.BOTAO_IMPRIMIR)) {
                return config;
            }
        });

        const configuracoesNaoFiscais = configuracoesComanda?.filter(config => config.tipo != TipoAnexoPedido.nota_fiscal);
        configuracoesNaoFiscais?.forEach((config, index) => {
            setTimeout(async () => {
                await gerarAnexoPedido(pedidoEncontrado.uuid, config.tipo, config.uuid, produtosSelecionados);
            }, 1000 * (index + 1));
        });
        setProdutosSelecionados([]);
    }

    const gerarAnexosBotaoFecharPedido = async (pedido: IPedido, dados?: IDadosEmitirNotaFiscal) => {
        if(switchNotaFiscal == true) {
            setTimeout(async () => await emitirNotaFiscalConsumidor(pedido.uuid, dados), 1000);
            handleModalNotaFiscal();
        }

        const configuracoesComanda = fluxoContexto?.configuracoesComandas.filter(config => {
            if(config.gatilhos.includes(TipoGatilhoComanda.BOTAO_CONCLUIR) && config.obrigatorio) {
                return config;
            }
        });

        const configuracoesNaoFiscais = configuracoesComanda?.filter(config => config.tipo != TipoAnexoPedido.nota_fiscal);
        configuracoesNaoFiscais?.forEach((config, index) => {
            setTimeout(async () => {
                await gerarAnexoPedido(pedido.uuid, config.tipo, config.uuid);
            }, 1000 * (index + 1));
        });
    }

    const aumentarQuantidade = async (uuid: string) => {
        if(pagamentos.length) {
            await alterarPagamentos([]);
            snackbar("O pagamento foi removido", { severity: "info" })
        }

        if (!produtos || !produtos.length) {
            return snackbar("Nenhum produto no carrinho", { severity: "error" });
        }

        const index = produtos.findIndex(produto => produto.uuid === uuid);

        if (index === -1) {
            return snackbar("Produto não encontrado", { severity: "error" });
        }

        produtos[index].quantidade = (produtos[index].quantidade ?? 0) + 1;
        setProdutos([...produtos]);

        const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado)
        if (pedidoEncontrado) {
            await adicionarProdutoPedido(produtos[index], pedidoEncontrado.uuid);
        }
    };

    const diminuirQuantidade = async (uuid: string) => {
        if(pagamentos.length) {
            await alterarPagamentos([]);
            snackbar("O pagamento foi removido", { severity: "info" })
        }

        if (!produtos || !produtos.length) {
            return snackbar("Nenhum produto no carrinho", { severity: "error" });
        }

        const index = produtos.findIndex(produto => produto.uuid === uuid);

        if (index === -1) {
            return snackbar("Produto não encontrado", { severity: "error" });
        }

        produtos[index].quantidade = (produtos[index].quantidade ?? 0) - 1;

        setProdutos([...produtos]);
        if (!produtos[index].quantidade) {
            removerProduto(uuid)
        } else {
            const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado);
            if (pedidoEncontrado) {
                await removerProdutoPedido(produtos[index].uuid, pedidoEncontrado.uuid)
            }
        }
    };

    const removerProduto = async (uuid: string) => {
        if(pagamentos.length) {
            await alterarPagamentos([]);
            snackbar("O pagamento foi removido", { severity: "info" })
        }

        const index = produtos.findIndex(produto => produto.uuid === uuid);

        if (index === -1) {
            return snackbar("Não foi possível remover o produto", { severity: "error" });
        }

        const pedidoEncontrado = pedidos?.find(pedidoProcurado => pedidoProcurado.uuid === pedidoSelecionado);
        const quantidade = !produtos[index].quantidade ? 1 : produtos[index].quantidade;

        if (pedidoEncontrado) {
            await removerProdutoPedido(
                produtos[index].uuid,
                pedidoEncontrado.uuid,
                quantidade
            )
        }

        produtos.splice(index, 1);
        const produtosAtualizados = [...produtos];
        setProdutos(produtosAtualizados);
    }

    const agendarHorario = (hora: moment.Moment | null) => {
        if (hora) {
            setAgendadoPara(hora);
        }
    }

    const alterarEndereco = () => {
        setEndereco(undefined);
    }

    const removerCliente = () => {
        setEndereco(undefined);
        setCliente(undefined);
    }

    const vincularCliente = (cliente: ICliente) => {
        setCliente(cliente);
    }

    const vincularEndereco = async (props: IEndereco) => {
        let regiao = props?.regiao;

        if(configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega.DISTANCIA) {
            if(!configuracao?.distanciasEntrega?.length) {
                return snackbar("Nenhuma distância configurada para calcular o frete", { severity: 'error' });
            }

            if(!estabelecimento?.endereco) {
                return snackbar("O endereço do estabelecimento não está configurado", { severity: 'error' });
            }

            setLoadingVincularEndereco(true);

            const regiaoGerada = await calcularFrete({
                enderecoDestino: props,
                distancias: configuracao?.distanciasEntrega,
                enderecoOrigem: estabelecimento?.endereco
            });

            setLoadingVincularEndereco(false);

            if(!regiaoGerada) {
                return;
            }

            regiao = regiaoGerada;
        }

        if(configuracao?.tipoCobrancaEntrega == TipoCobrancaEntrega.REGIAO) {
            if(!regiao) {
                return snackbar("Região não configurada", { severity: "error" });
            }
        }

        const endereco = {
            ...props,
            regiao
        }
        setEndereco(endereco);

        if (pedidoSelecionado) {
            const enderecoAlterado = await atualizarEndereco(pedidoSelecionado, endereco);
            handleRefresh();
            if (!enderecoAlterado) {
                return snackbar("Ocorreu um erro ao alterar o endereço", { severity: "error" })
            }

            snackbar("Endereço alterado com sucesso", { severity: "success" })
        }
    }

    const selecionarProduto = (produto: IProdutoPedido) => {
        const produtoIndex = produtosSelecionados.findIndex(produtoProcurado => produtoProcurado.uuid == produto.uuid);

        if (produtoIndex !== -1) {
            const novosProdutos = produtosSelecionados;
            novosProdutos.splice(produtoIndex, 1);
            setProdutosSelecionados([...novosProdutos]);
            return;
        }

        setProdutosSelecionados([...produtosSelecionados, produto]);
    }

    return (
        <OperacaoContext.Provider value={{
            adicionarProduto,
            editarProduto,
            configuracao,
            produtos,
            valorTotalProdutos,
            valorTotalPedido,
            alterarPagamentos,
            pagamentos,
            aumentarQuantidade,
            diminuirQuantidade,
            removerProduto,
            abrirPedido,
            loading,
            pedidoSelecionado,
            selecionarPedido,
            alterarTipo,
            tipo,
            pedidos,
            fecharPedido,
            valorRecebido,
            valorTroco,
            agendadoPara,
            agendarHorario,
            alterarEndereco,
            cliente,
            endereco,
            removerCliente,
            valorFrete,
            vincularCliente,
            vincularEndereco,
            salvarPedido,
            cancelarPedido,
            resetarTudo,
            numeroTotalItens,
            numeroPedidoDiario,
            handleModalNotaFiscal,
            handleSwitchNotaFiscal,
            modalNotaFiscal,
            switchNotaFiscal,
            selecionarProduto,
            produtosSelecionados,
            fluxoContexto,
            configuracaoNFCe,
            gerarAnexosBotaoImprimirComandas,
            loadingVincularEndereco
        }}>
            {children}
        </OperacaoContext.Provider>
    );
} 