import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../../core/routes/PrivateRoutes";
import { Operacao } from "../pages/Operacao";
import { OperacaoProvider } from "../contexts/OperacaoContext";
import { ConfiguracoesPedidoProvider } from "../contexts/ConfiguracoesPedidoContext";
import { HeaderContextProvider } from "../../core/contexts/HeaderContext";
import { Pedido } from "../pages/Pedido";
import { IEstabelecimento } from "../../estabelecimento/interfaces/IEstabelecimento";
import { EsteiraPedidos } from "../pages/EsteiraPedidos";
import { Layout } from "../../core/components/Layout";
import { useTamanhoTela } from "../../core/hooks/useTamanhoTela";
import { useAutenticacaoContext } from "../../core/contexts/AutenticacaoContext";
import { IMenu } from "../../core/interfaces/IMenu";
import { useMemo } from "react";
import { ListagemPedidos } from "../pages/ListagemPedidos";

interface IPedidosRoutesProps {
    autenticado: boolean;
}

export const PedidosRoutes = ({ autenticado }: IPedidosRoutesProps) => {
    const { modeloTela } = useTamanhoTela();

    const { perfil } = useAutenticacaoContext();

    const itensMenu: IMenu[] = useMemo(() => {
        const itens = [
            {
                titulo: "PDV",
                url: "/pedidos/operacao",
                subitens: []
            },
            {
                titulo: "Operação",
                url: "/pedidos/listagem",
                subitens: []
            }
        ];

        return itens
    }, [perfil])

    return (
        <HeaderContextProvider>
            <ConfiguracoesPedidoProvider>
                <OperacaoProvider>
                    <Routes>
                        <Route element={
                            <Layout
                                titulo="Pedidos"
                                itensMenu={itensMenu}
                                modeloTela={modeloTela}
                                drawer
                            >
                                <PrivateRoutes
                                    autenticado={autenticado}
                                />
                            </Layout>
                        }>
                            <Route path="/operacao" element={<Operacao />} />
                            <Route path="/:uuid" element={<Pedido />} />
                            <Route path="/esteira" element={<EsteiraPedidos />} />
                            <Route path="/listagem" element={<ListagemPedidos />} />
                            <Route path="*" element={<Navigate to="/pagina-nao-encontrada" />} />
                        </Route>
                    </Routes>
                </OperacaoProvider>
            </ConfiguracoesPedidoProvider>
        </HeaderContextProvider >
    );
}