import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { IEndereco } from "../../../core/interfaces/IEndereco";
import { ICliente } from "../../interface/ICliente";
import { SalvarEndereco } from "../SalvarEndereco";
import { useClientes } from "../../hooks/useClientes";
import { ButtonsContainer, PaperContainer } from "./styles";
import { Box, Button, ButtonGroup, CircularProgress, ClickAwayListener, FormControl, Grow, IconButton, MenuItem, MenuList, Paper, Popper, RadioGroup, Typography } from "@mui/material";
import { RadioCard } from "../../../core/components/RadioCard";
import { v4 } from "uuid";
import { useMoeda } from "../../../core/hooks/useMoeda";
import { Add, ArrowDropDown, Delete, Edit } from "@mui/icons-material"; import { useHotkeys } from "react-hotkeys-hook";
import { useConfiguracoesPedidoContext } from "../../../pedidos/contexts/ConfiguracoesPedidoContext";
;

interface IOpcao {
    titulo: string;
    funcao?: any;
    desabilitado?: boolean | undefined;
    loading?: boolean;
}

type OpcoesEndereco = "VINCULAR_ENDERECO" | "EDITAR_ENDERECO" | "EXCLUIR_ENDERECO";

interface GestaoEnderecosClienteProps {
    cliente: ICliente
    salvar: (endereco: IEndereco) => void
    cancelar: () => void;
    opcoes: OpcoesEndereco[]
    loading?: boolean;
}

export const GestaoEnderecosCliente = ({ cancelar, cliente, salvar, opcoes: opcoesString, loading }: GestaoEnderecosClienteProps) => {
    const { configuracao } = useConfiguracoesPedidoContext();
    const { listarEnderecos, excluirEndereco, salvarEndereco, loading: loadingClientes } = useClientes();
    const [enderecos, setEnderecos] = useState<IEndereco[]>([]);
    const [enderecoSelecionado, setEnderecoSelecionado] = useState<string | undefined>(undefined);
    const { real, number } = useMoeda();
    const [sessao, setSessao] = useState<"SELECIONAR_ENDERECO" | "CADASTRAR_EDITAR_ENDERECO">("SELECIONAR_ENDERECO")
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const botaoAcaoRef = useRef<any>();
    const endereco = useMemo(() => {
        if (enderecoSelecionado) {
            const enderecoEncontrado = enderecos.find(enderecoProcurado => enderecoProcurado.uuid === enderecoSelecionado);
            return {
                ...enderecoEncontrado,
                regiao: enderecoEncontrado?.regiao ? {
                    ...enderecoEncontrado?.regiao,
                    preco: number(enderecoEncontrado?.regiao?.preco as unknown as string)
                } : undefined
            };
        }
    }, [enderecoSelecionado]) as IEndereco

    const opcoes = useMemo(() => {
        const retorno = [];

        if (opcoesString.includes("VINCULAR_ENDERECO")) {
            const opcao: IOpcao = {
                titulo: "Vincular",
                desabilitado: !endereco,
            }

            if (endereco) {
                opcao.funcao = () => salvar(endereco)
            }

            retorno.push(opcao)
        }

        if (opcoesString.includes("EDITAR_ENDERECO")) {
            const opcao: IOpcao = {
                titulo: "Editar",
                desabilitado: !endereco,
            }

            if (endereco) {
                opcao.funcao = async () => {
                    if (enderecoSelecionado) {
                        setSessao("CADASTRAR_EDITAR_ENDERECO");
                    }
                }
            }

            retorno.push(opcao)
        }

        if (opcoesString.includes("EXCLUIR_ENDERECO")) {
            const opcao: IOpcao = {
                titulo: "Excluir",
                desabilitado: !endereco,
            }

            if (endereco) {
                opcao.funcao = async () => {
                    if (endereco.uuid && cliente.uuid) {
                        const resultado = await excluirEndereco(endereco.uuid, cliente.uuid);
                        if (resultado) {
                            setEnderecoSelecionado(undefined);
                            handleListarEnderecos(cliente.uuid);
                        }
                    }
                }
            }

            retorno.push(opcao)
        }

        return retorno
    }, [enderecoSelecionado])

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (opcoes[selectedIndex]?.funcao && !(opcoes[selectedIndex]?.desabilitado)) {
                opcoes[selectedIndex].funcao();
            }
        }
    };

    useEffect(() => {
        const button = botaoAcaoRef.current;
        if (button) {
            button.focus();
            button.addEventListener('keydown', handleKeyDown);

            return () => {
                button.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [opcoes, selectedIndex, enderecoSelecionado]);


    useEffect(() => {
        if (opcoes.length > 0) {
            setSelectedIndex(0);
        }
    }, [opcoes]);

    const handleAcaoClick = () => {
        const opcao = opcoes[selectedIndex];
        if (opcao && opcao.funcao) {
            opcao.funcao();
        }
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleSalvarEndereco = async (endereco: IEndereco, clienteUuid: string) => {
        const resultado = await salvarEndereco(endereco, clienteUuid);
        if (resultado) {
            handleListarEnderecos(clienteUuid);
        }
        return resultado;
    }

    const handleListarEnderecos = async (clienteUuid: string) => {
        const enderecosEncontrados = await listarEnderecos(clienteUuid);
        if (!enderecosEncontrados?.length) {
            return setSessao("CADASTRAR_EDITAR_ENDERECO");
        }
        setEnderecos(enderecosEncontrados);
        setEnderecoSelecionado(enderecosEncontrados[0]?.uuid)
    }

    useEffect(() => {
        if (cliente?.uuid) {
            handleListarEnderecos(cliente.uuid);
        }
    }, [cliente])

    if (sessao === "CADASTRAR_EDITAR_ENDERECO") {
        return <SalvarEndereco
            valoresIniciais={endereco}
            salvarEndereco={handleSalvarEndereco}
            cancelar={cancelar}
            cliente={cliente}
            salvar={salvar}
            enderecos={enderecos}
            loading={loadingClientes || loading}
        />
    }

    return (
        <PaperContainer>
            <Box sx={{ width: "100%" }}>
                <Typography variant="h6" marginBottom="1rem">Selecione um endereço</Typography>
            </Box>
            <FormControl className="header-input">
                <RadioGroup
                    name="gerenciar-enderecos-cliente-group"
                    value={enderecoSelecionado}
                    onChange={(e, value) => setEnderecoSelecionado(value)}
                    row
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    {enderecos?.map(endereco => {
                        const componentesEndereco = [
                            `${endereco.rua} ${endereco.numero}`,
                            endereco.bairro,
                            endereco.complemento,
                            endereco.cep,
                            endereco.cidade,
                            endereco.regiao?.descricao
                        ];
                        const componentesEnderecosValidos = componentesEndereco.filter(value => value != undefined && value.length > 0);
                        const titulo = endereco.observacao?.length ? endereco.observacao : endereco?.rua;
                        const uuid = endereco.uuid ?? v4();
                        return (
                            <Box sx={{ mb: 2 }}>
                                <RadioCard
                                    onClick={() => setEnderecoSelecionado(uuid)}
                                    labelTitle={titulo}
                                    labelDescription={componentesEnderecosValidos.join(', ')}
                                    labelValue={uuid}
                                    key={uuid}
                                    selected={enderecoSelecionado == uuid}
                                />
                            </Box>
                        )
                    })}
                </RadioGroup>
            </FormControl>
            <Button
                startIcon={<Add />}
                variant="outlined"
                onClick={() => {
                    setEnderecoSelecionado(undefined);
                    setSessao("CADASTRAR_EDITAR_ENDERECO");
                }}
                sx={{ marginBottom: "1.5rem", width: "325px" }}
            >
                CADASTRAR ENDEREÇO
            </Button>
            <ButtonsContainer>
                <Button variant="outlined" onClick={cancelar}>
                    CANCELAR
                </Button>
                {(loadingClientes || loading) ? (
                    <CircularProgress
                        size={25}
                    />
                ) : (
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                        <Button
                            ref={botaoAcaoRef}
                            onClick={handleAcaoClick}
                            disabled={opcoes[selectedIndex]?.desabilitado ?? true}
                        >

                            {opcoes[selectedIndex] ? opcoes[selectedIndex].titulo : 'Selecione uma opção'}
                        </Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                        >
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                )}
                <Popper
                    sx={{
                        zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {opcoes.map((opcao, index) => {
                                            return (
                                                <MenuItem
                                                    key={opcao.titulo}
                                                    disabled={opcao.desabilitado}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {opcao.titulo}
                                                </MenuItem>
                                            )
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </ButtonsContainer>
        </PaperContainer>
    );
}